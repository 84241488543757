import React, { useState, useEffect, useRef } from "react";
import Customer from "./customer";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const EventCustomer = ({ itemsPerPage = "8" }) => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const refReactPaginate = useRef();
  const data = [
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2021/06/Strawberry-Water-006.jpg",
      store_name: "acouplecooks",
      name: "strawberry water",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: false,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2020/07/Iced-Tea-001-735x919.jpg",
      store_name: "acouplecooks",
      name: "iced tea",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2020/12/Pomegranate-Juice-016s-735x919.jpg",
      store_name: "acouplecooks",
      name: "pomegranate juice",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2013/06/Kombucha-Recipe-005-735x919.jpg",
      store_name: "acouplecooks",
      name: "kombucha",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: false,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2021/03/Lime-Rickey-003-735x919.jpg",
      store_name: "acouplecooks",
      name: "lime rickey",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2021/06/Strawberry-Water-006.jpg",
      store_name: "acouplecooks",
      name: "strawberry water",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: false,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2020/07/Iced-Tea-001-735x919.jpg",
      store_name: "acouplecooks",
      name: "iced tea",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2020/12/Pomegranate-Juice-016s-735x919.jpg",
      store_name: "acouplecooks",
      name: "pomegranate juice",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2013/06/Kombucha-Recipe-005-735x919.jpg",
      store_name: "acouplecooks",
      name: "kombucha",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: false,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2021/03/Lime-Rickey-003-735x919.jpg",
      store_name: "acouplecooks",
      name: "lime rickey",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2021/06/Strawberry-Water-006.jpg",
      store_name: "acouplecooks",
      name: "strawberry water",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: false,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2020/07/Iced-Tea-001-735x919.jpg",
      store_name: "acouplecooks",
      name: "iced tea",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2020/12/Pomegranate-Juice-016s-735x919.jpg",
      store_name: "acouplecooks",
      name: "pomegranate juice",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2013/06/Kombucha-Recipe-005-735x919.jpg",
      store_name: "acouplecooks",
      name: "kombucha",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: false,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2021/03/Lime-Rickey-003-735x919.jpg",
      store_name: "acouplecooks",
      name: "lime rickey",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2021/06/Strawberry-Water-006.jpg",
      store_name: "acouplecooks",
      name: "strawberry water",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: false,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2020/07/Iced-Tea-001-735x919.jpg",
      store_name: "acouplecooks",
      name: "iced tea",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2020/12/Pomegranate-Juice-016s-735x919.jpg",
      store_name: "acouplecooks",
      name: "pomegranate juice",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2013/06/Kombucha-Recipe-005-735x919.jpg",
      store_name: "acouplecooks",
      name: "kombucha",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: false,
    },
    {
      img: "https://www.acouplecooks.com/wp-content/uploads/2021/03/Lime-Rickey-003-735x919.jpg",
      store_name: "acouplecooks",
      name: "lime rickey",
      price: "2500",
      quantity: "2",
      subtotal: "5000",
      date: "12/02/2022",
      status: true,
    },
  ];
  const [customers, setCustomers] = useState([]);

  const sendMessageHandler = () => {
    console.log(
      "editorState => ",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );

    setTimeout(() => {
      setShowModal(false);
      setLoading(false);
    }, 3000);
    setLoading(true);
  };

  const getAllCustomers = () => {
    setTimeout(() => {
      setCustomers(data);
      setLoading(false);
    }, 4000);
    setLoading(true);
  };
  useEffect(() => {
    getAllCustomers();
  }, []);

  useEffect(() => {
    if (customers.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = customers.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(customers.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, customers]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % customers.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <Customer
      sendMessageHandler={sendMessageHandler}
      loading={loading}
      showModal={showModal}
      pageNum={pageNum}
      setShowModal={setShowModal}
      editorState={editorState}
      handlePageClick={handlePageClick}
      pageCount={pageCount}
      refReactPaginate={refReactPaginate}
      setEditorState={setEditorState}
      currentItems={currentItems}
    />
  );
};

export default EventCustomer;
