import React, { useContext, useState } from "react";
import Input from "../../component/input/input";
import { Modal } from "../../component/modal/modal";
import Classes from "./withdrawal.module.css";
import Button from "../../component/button/button";
import toast from "react-hot-toast";
import { MainContext } from "../../App";

const ModalSection = ({ showModal, setShowModal }) => {
  const [value, setValue] = useState(["", "", "", "", "", "", "", "", "", ""]);
  const [ref] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const CTX = useContext(MainContext);
  const [enterPhone, setEnterPhone] = useState(false);

  const [inputs, setInputs] = useState({ amount: 0 });
  const [loading, setLoading] = useState(false);

  const [OTP, setOTP] = useState(false);
  // const formOnSubmitHandler = () => {};

  const onChangeValue = (newValue, index) => {
    if (newValue.match(/(\d|^(?![\s\S]))/g) && newValue.length <= 2) {
      setValue([
        ...value.slice(0, index),
        newValue.slice(-1),
        ...value.slice(index + 1),
      ]);
      if (newValue.length > 0 && ref[index + 1]) {
        ref[index + 1].focus();
      }
    }
  };

  const onRemove = (key, index) => {
    if (key === "Backspace") {
      ref[index - 1].focus();
    }
  };

  const requestOTPHandler = async () => {
    if (!inputs.amount) return toast(`cannot withdraw 0 naira`);
    setLoading(true);
    try {
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/logistic/wallet/verify/withdraw`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${CTX.token}`,
          },
          body: JSON.stringify({
            amount: +inputs.amount,
            phone_number: CTX.user.phone,
          }),
        }
      );

      const json = await fetched.json();
      console.log("json", json);
      setLoading(false);

      if (json.message === "error") {
        toast(json.data.amount);
        return;
      } else if (json.status === 400) {
        toast(json.message);
      } else {
        setOTP(true);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast("Check your internet connections");
      console.log(error);
    }
  };

  const submitHandler = async () => {
    setLoading(true);
    try {
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/logistic/wallet/withdraw`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${CTX.token}`,
          },
          body: JSON.stringify({
            otp: value.join(""),
          }),
        }
      );

      const json = await fetched.json();
      console.log("json", json);
      setLoading(false);

      if (json.message === "error") {
        toast(json.data.otp);
        return;
      } else {
        window.location.reload();
        toast("Withdrawal successful");
        return;
      }
    } catch (error) {
      setLoading(false);
      toast("Check your internet connections");
      console.log(error);
    }
  };

  const closeHandler = () => {
    setShowModal(false);
    setEnterPhone(false);
    setInputs({ amount: "" });
    setOTP(false);
  };

  return (
    <Modal top="50%" show={showModal} close={closeHandler}>
      <h3 className={Classes.confirmRemoval}>
        {enterPhone ? "Request OTP" : "Withdraw Fund"}
      </h3>

      {/* <form className={Classes.cover} onSubmit={formOnSubmitHandler}> */}
      {!enterPhone && (
        <div className={Classes.amountCover}>
          <div className={Classes.nairaCover}>₦</div>
          <Input
            label="Amount"
            style={{ paddingLeft: "60px" }}
            required
            marb={true}
            type="input"
            readOnly={OTP}
            value={inputs?.amount}
            onChange={(e) =>
              setInputs({
                ...inputs,
                amount: e.target.value,
              })
            }
            inputType="number"
          />
        </div>
      )}
      {!OTP && (
        <>
          {enterPhone && (
            <>
              <div
                className={Classes.emptyArrayCover}
                style={{
                  height: "69px",
                  marginTop: "0px",
                  marginBottom: "14px",
                }}
              >
                <small>We will send a 6 digit code to you via SMS</small>
              </div>
              <Input
                label="Phone Number"
                required
                // marb={true}
                type="input"
                inputType="number"
                placeholder="Enter phone number"
              />
            </>
          )}
        </>
      )}
      {OTP && (
        <div>
          <div
            className={Classes.emptyArrayCover}
            style={{ height: "61px", marginTop: "7px", marginBottom: "14px" }}
          >
            <small>Enter the 6 digit code we sent to you via SMS</small>
          </div>
          <div className={Classes.allInputCover}>
            <input
              ref={(input) => {
                ref[1] = input;
              }}
              value={value[1]}
              onChange={({ target }) => onChangeValue(target.value, 1)}
              onKeyUp={({ key }) => onRemove(key, 1)}
            />
            <input
              ref={(input) => {
                ref[2] = input;
              }}
              value={value[2]}
              onChange={({ target }) => onChangeValue(target.value, 2)}
              onKeyUp={({ key }) => onRemove(key, 2)}
            />
            <input
              ref={(input) => {
                ref[3] = input;
              }}
              value={value[3]}
              onChange={({ target }) => onChangeValue(target.value, 3)}
              onKeyUp={({ key }) => onRemove(key, 3)}
            />
            <input
              ref={(input) => {
                ref[4] = input;
              }}
              value={value[4]}
              onChange={({ target }) => onChangeValue(target.value, 4)}
              onKeyUp={({ key }) => onRemove(key, 4)}
            />
            <input
              ref={(input) => {
                ref[5] = input;
              }}
              value={value[5]}
              onChange={({ target }) => onChangeValue(target.value, 5)}
              onKeyUp={({ key }) => onRemove(key, 5)}
            />
            <input
              ref={(input) => {
                ref[6] = input;
              }}
              value={value[6]}
              onChange={({ target }) => onChangeValue(target.value, 6)}
              onKeyUp={({ key }) => onRemove(key, 6)}
            />
          </div>
        </div>
      )}

      <div className={Classes.flexRight}>
        {OTP ? (
          <Button
            text="Submit"
            style={{ marginTop: "30px", height: "38px" }}
            loading={loading}
            onClick={submitHandler}
          />
        ) : (
          <Button
            text="Request OTP"
            style={{ marginTop: "30px", height: "38px" }}
            loading={loading}
            onClick={requestOTPHandler}
          />
        )}
        <Button
          text="Cancel"
          onClick={closeHandler}
          style={{
            marginLeft: "20px",
            marginTop: "auto",
            backgroundColor: "#fff",
            color: "#ff0066",
            height: "38px",
          }}
        />
      </div>
      {/* </form> */}
    </Modal>
  );
};

export default ModalSection;
