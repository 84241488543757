import React, { useState, useEffect, useContext } from "react";
import EachOrder from "./eachOrder";
import { useParams } from "react-router-dom";
import { MainContext } from "../../App";
import toast from "react-hot-toast";

const EventEachOrder = () => {
  const CTX = useContext(MainContext);
  const orderParams = useParams();

  const [orders, setOrders] = useState({ order: [] });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const getEachOrder = async () => {
    // alert("hello world")
    // setTimeout(() => {
    //   setOrders(data);
    //   setLoading(false);
    // }, 4000);
    // setLoading(true);

    try {
      setLoading(true);
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/logistic/orders/${orderParams.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${CTX.token}`,
          },
        }
      );
      const json = await fetched.json();
      setLoading(false);

      console.log("json => ", json);
      setOrders(json.data);
    } catch (error) {
      // console.log("v data => ", data)
      // setLoading(false);
      // setOrders(data);
      // setOrders({})
      toast("Check your internet");
    }
  };

  useEffect(() => {
    getEachOrder();
  }, []);

  const changeStatus = async ({ id, status }) => {
    try {
      setLoading(true);
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/logistic/orders/status/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${CTX.token}`,
          },
          body: JSON.stringify({
            status,
          }),
        }
      );
      const json = await fetched.json();
      setLoading(false);

      getEachOrder();

      console.log("json => ", json);
      // setOrders(data);
      // setOrders(json.data)
      if (json.status === 400) {
        return toast(json.data.order ?? json.message);
      }
    } catch (error) {
      // console.log("v data => ", data)
      // setLoading(false);
      // setOrders(data);
      // setOrders({})
      toast("Check your internet");
      console.log(error);
    }
  };

  return (
    <EachOrder
      orderId={orderParams.id}
      changeStatus={changeStatus}
      loading={loading}
      orders={orders}
      setStatus={setStatus}
      status={status}
    />
  );
};

export default EventEachOrder;
