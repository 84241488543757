import React, { Suspense, useContext, useEffect, useState } from "react";
import Classes from "./wallet.module.css";
import DashboardCard from "../../component/dashboardCard/dashboardCard";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import { AiOutlineLock, AiOutlineSearch } from "react-icons/ai";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";
import { ReactComponent as Money } from "../../asset/money.svg";
import { Months } from "../../data/monthsJson";
import { MainContext } from "../../App";
import { AiOutlineWallet } from "react-icons/ai";

import ReactPaginate from "react-paginate";
import { ReactComponent as Left } from "../../asset/left.svg";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import { GetUserStatistics } from "../../helper/getUserStatistics";

const Wallet = ({
  currentItems,
  pageCount,
  refReactPaginate,
  handlePageClick,
  loading,
  selectOnChangeHandler,
  onChangeHandler,
  transaction,
}) => {
  const CTX = useContext(MainContext);
  const [inputValue, setInputValue] = useState("");
  const { data, getSta } = GetUserStatistics();

  useEffect(() => {
    getSta();
    if (data.balance) {
      CTX.setStats(data);
    }
  }, []);

  const mappedTransaction = currentItems
    .filter((v) =>
      v.store_name.toLowerCase().includes(inputValue.toLowerCase())
    )
    .map((v, i) => (
      <>
        <tr
          key={i}
          className={`${
            i % 2 !== 0 && "bg-gray-200"
          } md:table-row hidden text-sm`}
        >
          <td className={`py-5 `}>{v?.store_name}</td>

          <td className={``}>{v?.date}</td>

          <td className={``}>{v?.shipping_fee}</td>

          <td className={``}>{v?.amount}</td>
        </tr>
        <div
          className={`p-5 space-y-3 ${
            i % 2 !== 0 && "bg-gray-200"
          } relative md:hidden`}
        >
          <p className="font-bold text-[#6c757d]">
            Store Name: <span className="font-light">{v?.store_name}</span>
          </p>

          <p className="font-bold text-[#6c757d]">
            Date: <span className="font-light">{v.date}</span>
          </p>
          <p className="font-bold text-[#6c757d]">
            shipping Fee: <span className="font-light"> {v?.shipping_fee}</span>
          </p>

          <p className="font-bold text-[#6c757d]">
            Amount: <span className="font-light"> {v?.amount}</span>
          </p>
        </div>

        {/* <div
    key={i}
    className={Classes.mappedOrders}
    style={{gridTemplateColumns: '1fr 0.6fr 0.6fr'}}
  >
    <div className={Classes.myOrderItemCover}>
      <strong className={Classes.strongSection}> Store Name: </strong>
      {v.store_name}
    </div>
    <div className=''></div>
    <div className={Classes.myOrderItemCover}>{v.date}</div>
    <div className={Classes.myOrderItemCover}>
      <strong className={Classes.strongSection}> Amount: </strong>
      {v.amount}
    </div>
  </div> */}
      </>
    ));

  const mappedLoading = Array(5)
    .fill("s")
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Wallet - Passward Logistics </title>
        <meta name="og:title" content="Wallet - Passward Logistics" />
        <meta name="og:description" content="Wallet - Passward Logistics" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: "0px 20px",
          boxSizing: "border-box",
          width: "100%",
          filter: "blur(var(--body-blur,0px))",
        }}
      >
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Income" />
          <div className={Classes.gridDashboardCard}>
            <DashboardCard
              label="Wallet Balance"
              num={
                transaction.wallet_balance
                  ? "₦" + " " + transaction.wallet_balance
                  : "₦" + " " + 0
              }
              svg={<AiOutlineWallet />}
            />

            <DashboardCard
              label="Safe Lock"
              num={
                transaction.safe_lock
                  ? "₦" + " " + transaction.safe_lock
                  : "₦" + " " + 0
              }
              svg={<AiOutlineLock />}
            />

            <div className={Classes.DashboardCardCover}>
              <div className={Classes.svgCover}>
                <Money />
              </div>
              <div className={Classes.textCoverSection}>
                {/* <p style={{margin: '0px 0px  5px 0px', fontSize: '13px'}}>
                  Total Revenue
                </p> */}

                <select
                  onChange={selectOnChangeHandler}
                  className={Classes.selectRevenue}
                >
                  {/* <option>All Revenue</option> */}
                  {Months.map((v, i) => (
                    <option key={i} selected={i === new Date().getMonth()}>
                      {v.name} Income
                    </option>
                  ))}
                </select>

                <strong style={{ fontSize: "13px" }}>
                  {transaction?.monthly_balance?.amount}
                </strong>
              </div>
            </div>
          </div>

          <div className={Classes.subNavCover}>
            <form className={Classes.firstForm}>
              <input
                placeholder="Search Name"
                className={Classes.searchFormSection}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button className={Classes.buttonFormSection}>
                <AiOutlineSearch fill="#fff" size="1.3em" />
              </button>
            </form>
            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>Income</h3>
              <div className={Classes.sideEachPageHeader}>
                <form className={Classes.secondForm}>
                  <input
                    placeholder="Search Name"
                    className={Classes.searchFormSection}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
              </div>
            </div>
          </div>

          {/* main order section where the orders are mapped */}

          <table className="w-full hidden md:table my-5 text-center">
            {mappedTransaction.length > 0 && (
              <>
                <thead className="font-bold w-full  text-[#6c757d]">
                  <tr className="bg-gray-200 w-full md:text-[1rem] text-xs">
                    <th className="py-5 ">Store Name</th>
                    <th className="">Date</th>
                    <th className="">Shipping Fee</th>
                    <th className="">Amount</th>
                  </tr>
                </thead>
                <tbody className="w-full ">
                  {loading ? (
                    mappedLoading
                  ) : mappedTransaction.length < 1 ? (
                    <div className={Classes.noIncome}>
                      You have no income history{" "}
                    </div>
                  ) : (
                    mappedTransaction
                  )}
                </tbody>
              </>
            )}
          </table>
          <div className="md:hidden">
            {loading ? (
              mappedLoading
            ) : mappedTransaction.length < 1 ? (
              <div className={Classes.noIncome}>
                You have no income history{" "}
              </div>
            ) : (
              mappedTransaction
            )}
          </div>

          {mappedTransaction.length > 0 && (
            <div
              style={{ display: "flex", marginBottom: "30px", width: "100%" }}
            >
              <div className={Classes.showingCover}>
                <select
                  onChange={onChangeHandler}
                  className={Classes.showingSelectCover}
                >
                  <option>Showing 1</option>
                  <option defaultValue selected>
                    Showing 10
                  </option>
                  <option>Showing 25</option>
                  <option>Showing 50</option>
                  <option>Showing 100</option>
                </select>
              </div>
              <ReactPaginate
                ref={refReactPaginate}
                breakLabel="..."
                nextLabel={<Left style={{ transform: "rotate(180deg)" }} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<Left />}
                className={`${Classes.paginationClass} md:mt-0 mt-3 }`}
                renderOnZeroPageCount={null}
                disabledClassName={Classes.disabledClassName}
              />
            </div>
          )}
        </Suspense>
      </div>
      {/* <ModalSection showModal={showModal} setShowModal={setShowModal} /> */}
    </div>
  );
};

export default Wallet;
