import React, { Suspense, useContext, useEffect } from "react";

import Classes from "./eachOrder.module.css";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";
import { MainContext } from "../../App";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import DashboardCard from "../../component/dashboardCard/dashboardCard";
import { ReactComponent as Money } from "../../asset/money.svg";
import { ReactComponent as Store } from "../../asset/store.svg";
import { ReactComponent as Box } from "../../asset/box.svg";
import { ReactComponent as Bag } from "../../asset/bag.svg";
import { Helmet } from "react-helmet";
import { GetUserStatistics } from "../../helper/getUserStatistics";
import checkPrice from "../../utils/checkPrice";
import joinClasses from "../../utils/joinClasses";

const EachOrder = ({ loading, orders, changeStatus }) => {
  const CTX = useContext(MainContext);
  const { data, getSta } = GetUserStatistics();

  const mapMyOrders = orders?.order.map((v, i) => {
    return (
      <>
        <tr
          key={i}
          className={joinClasses(
            "text-center sm:table-row hidden",
            i % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
          )}
        >
          <td className="text-start flex py-5 pl-5">
            <img
              src={v.product_thumbnail}
              className={Classes.myOrderItemImage}
              alt="item"
            />
            <p>
              <small>
                From {v?.store_detail?.store_name} stores (
                {v?.store_detail?.store_address
                  ? `${v?.store_detail?.store_address},`
                  : ""}{" "}
                {v?.store_detail?.store_state})
              </small>
              <strong style={{ display: "block", marginTop: "2px" }}>
                {v.product_title}
              </strong>
            </p>
          </td>
          <td className="py-2">
            <span>
              ₦
              {checkPrice(
                Number(v?.product_discount_price),
                Number(v?.product_price)
              )}
            </span>
          </td>
          <td className="py-2">
            <span>{v.product_qty}</span>
          </td>
          <td className="py-2">
            <span>₦{v.product_qty * v?.product_discount_price}</span>
          </td>
          <td className="py-2 relative ">
            <select
              onChange={(e) =>
                changeStatus({ id: v.individual_order, status: e.target.value })
              }
              className={joinClasses(
                " p-2 text-white text-sm rounded-lg",
                v.delivery_status?.toUpperCase() === "DELIVERED"
                  ? "bg-green-500"
                  : v.delivery_status?.toUpperCase() === "CANCELLED"
                  ? "bg-[#ff0066]"
                  : v.delivery_status?.toUpperCase() === "PROCESSING"
                  ? "bg-yellow-500"
                  : v.delivery_status?.toUpperCase() === "PENDING"
                  ? "bg-amber-500"
                  : "bg-transparent"
              )}
              value={v.delivery_status}
              defaultValue={v.delivery_status}
              disabled={
                v.delivery_status?.toUpperCase() === "CANCELLED" ||
                v.delivery_status?.toUpperCase() === "DELIVERED"
              }
            >
              <option className="py-5" value="PENDING">
                Pending
              </option>
              <option className="py-5" value="CANCELLED">
                Cancelled
              </option>
              <option className="py-5" value="PROCESSING">
                Processing
              </option>
              <option className="py-5" value="DELIVERED">
                Delivered
              </option>
            </select>
            {/* <div className="absolute top-0 left-0 w-[100px] h-[100px] bg-blue-400 z-20"></div> */}
          </td>
        </tr>

        <div
          className={joinClasses(
            "sm:hidden space-y-5 mb-5 p-5",
            i % 2 === 0 ? "bg-gray-200" : "bg-gray-300"
          )}
        >
          <div>
            <small>
              From {v?.store_detail?.store_name} stores (
              {v?.store_detail?.store_address
                ? `${v?.store_detail?.store_address},`
                : ""}{" "}
              {v?.store_detail?.store_state})
            </small>{" "}
          </div>
          <div>
            <span className="font-bold">Item:</span>{" "}
            <span>{v.product_title}</span>
          </div>
          <div className="relative">
            <span className="font-bold">Price:</span>{" "}
            <span>
              {" "}
              ₦
              {checkPrice(
                Number(v?.product_discount_price),
                Number(v?.product_price)
              )}
            </span>
            <img
              src={v.product_thumbnail}
              className={joinClasses(
                Classes.myOrderItemImage,
                "absolute top-0 right-0"
              )}
              alt="item"
            />
          </div>
          <div>
            <span className="font-bold">Quantity:</span>{" "}
            <span>{v.product_qty}</span>
          </div>
          <div>
            <span className="font-bold">Subtotal:</span>{" "}
            <span>₦{v.product_qty * v?.product_discount_price}</span>
          </div>

          <select
            onChange={(e) =>
              changeStatus({ id: v.individual_order, status: e.target.value })
            }
            className={joinClasses(
              " p-2 text-white text-sm rounded-lg",
              v.delivery_status?.toUpperCase() === "DELIVERED"
                ? "bg-green-500"
                : v.delivery_status?.toUpperCase() === "CANCELLED"
                ? "bg-[#ff0066]"
                : v.delivery_status?.toUpperCase() === "PROCESSING"
                ? "bg-yellow-500"
                : v.delivery_status?.toUpperCase() === "PENDING"
                ? "bg-amber-500"
                : "bg-transparent"
            )}
            value={v.delivery_status}
            defaultValue={v.delivery_status}
            disabled={
              v.delivery_status?.toUpperCase() === "CANCELLED" ||
              v.delivery_status?.toUpperCase() === "DELIVERED"
            }
          >
            <option className="py-5" value="PENDING">
              Pending
            </option>
            <option className="py-5" value="CANCELLED">
              Cancelled
            </option>
            <option className="py-5" value="PROCESSING">
              Processing
            </option>
            <option className="py-5" value="DELIVERED">
              Delivered
            </option>
          </select>
        </div>
      </>
    );
  });

  useEffect(() => {
    getSta();
    if (data.balance) {
      CTX.setStats(data);
    }
  }, []);

  const sumUp = (arr) => {
    const func = (total, num) => total + num;
    const intialValue = 0;
    const total = arr.reduce(func, intialValue);
    return total;
  };

  // if (loading) return <div className="mt-5">{mappedLoading}</div>;
  const productQuantites = orders.order.map((order) =>
    Number(order.product_qty)
  );
  const totalProductQty = sumUp(productQuantites);

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Orders - Passward Logistics </title>
        <meta name="og:title" content="Orders - Passward Logistics" />
        <meta name="og:description" content="Orders - Passward Logistics" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: "0px 20px",
          boxSizing: "border-box",
          width: "100%",
          filter: "blur(var(--body-blur,0px))",
        }}
      >
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Orders" />
          <div className={Classes.gridDashboardCard}>
            <DashboardCard
              label="Total Orders"
              num={`₦ ${orders?.shipping_total ?? 0}`}
              svg={<Money />}
            />
            <DashboardCard
              label="Date"
              num={orders?.order_placed_on}
              svg={<Bag />}
            />
            <DashboardCard
              label="Products"
              num={totalProductQty}
              svg={<Store width="16px" />}
            />
            <DashboardCard
              label="Customer"
              num={orders?.shipping_name}
              svg={<Box width="16px" />}
            />
          </div>

          {/* <div className={Classes.orderDetailsCover}>
            <form
              onSubmit={() => changeStatus({ id: orderId, status })}
              className={Classes.orderStatus}
            >
              <div>
                <strong style={{ marginRight: "6px" }}>Order Status:</strong>
                <button
                  style={{ backgroundColor: "#ff6251" }}
                  className={Classes.trueVisibilityCover}
                  disabled
                >
                  {orders?.order[0]?.delivery_status}
                </button>
              </div>
              {orders?.order[0]?.delivery_status.toLowerCase() !==
                "DELIVERED".toLowerCase() &&
                orders?.order[0]?.delivery_status.toLowerCase() !==
                  "CANCELLED".toLowerCase() && (
                  <>
                    <div style={{ marginTop: "10px" }}>
                      <small>Change Status</small>
                      <select
                        className={"border p-1 ml-2 rounded-md"}
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="PENDING">Pending</option>
                        <option value="CANCELLED">Cancelled</option>
                        <option value="PROCESSING">Processing</option>
                        <option value="DELIVERED">Delivered</option>
                      </select>
                    </div>

                    <div
                      style={{
                        marginTop: "10px",
                        width: "max-content",
                        marginLeft: "auto",
                      }}
                    >
                      <Button
                        loading={loading}
                        showLoading={false}
                        text="Save Status"
                        style={{ padding: "0.01em .8em", fontSize: "13px" }}
                      />
                    </div>
                  </>
                )}
            </form>
          </div> */}

          <div className={Classes.subNavCover}>
            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>
                Order ID: {orders?.order_code}
              </h3>
              <div className={Classes.sideEachPageHeader}>
                {/* <form className={Classes.secondForm}>
                  <input
                    placeholder="Search Order"
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form> */}
              </div>
            </div>
          </div>

          {/* main order sectioon where the orders are mapped */}
          <div className={joinClasses(Classes.cardCover, "hidden sm:block")}>
            <div className={Classes.mappedStoresCover}>
              <table className="w-full ">
                <thead className="bg-[#d8daf8] text-start">
                  <th className="py-5 text-start pl-5 w-[45%]">Item</th>
                  <th>Unit price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                  <th>Change status</th>
                </thead>

                <tbody>{mapMyOrders}</tbody>
              </table>
            </div>
          </div>

          <div className="sm:hidden">{mapMyOrders}</div>

          {/* order total */}
          <div className={"flex flex-col items-end space-y-5"}>
            <div>
              <strong>Total item{totalProductQty > 1 && "s"} price:</strong>
              <strong
                style={{
                  color: "#000033",
                  fontSize: "22px",
                  marginLeft: "15px",
                }}
              >
                ₦{loading ? "0" : orders?.shipping_total}
              </strong>
            </div>

            <div>
              <strong>Shipping fee:</strong>
              <strong
                style={{
                  color: "#000033",
                  fontSize: "22px",
                  marginLeft: "15px",
                }}
              >
                ₦ {orders.shipping_fee ?? 0}
              </strong>
            </div>

            <div>
              <strong>Grand Total:</strong>
              <strong
                style={{
                  color: "#000033",
                  fontSize: "22px",
                  marginLeft: "15px",
                }}
              >
                ₦ {orders.grand_total ?? 0}
              </strong>
            </div>
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default EachOrder;
