import React, { Suspense, useContext, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";

import Classes from "./orders.module.css";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";
import { MainContext } from "../../App";
import { ReactComponent as Left } from "../../asset/left.svg";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

const Orders = ({
  pageNum = { pageNum },
  refReactPaginate = { refReactPaginate },
  handlePageClick = { handlePageClick },
  pageCount = { pageCount },
  loading = { loading },
  currentItems = { currentItems },
  onChangeHandler,
}) => {
  const CTX = useContext(MainContext);
  const [inputValue, setInputValue] = useState("");

  const mappedOrders = currentItems
    .filter((v) =>
      v.order_code.toLowerCase().includes(inputValue.toLowerCase())
    )
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}> Order ID: </strong>
          {v.order_code}
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Products: </strong>
          {v.product_qty}
        </div>
        <div className={Classes.myOrderItemCover}>{v.order_placed_on}</div>
        <div className={Classes.myOrderItemCover}>
          ₦{Number(v?.product_qty) * Number(v?.product_discount_price)}
        </div>
        <div className="sm:absolute"></div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Payment Method: </strong>
          {v.order.payment_method}
        </div>

        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Order Status: </strong>
          <button
            style={{ backgroundColor: "#ff6251" }}
            className={Classes.trueVisibilityCover}
          >
            {v.delivery_status.toLowerCase()}
          </button>
        </div>
        <div className={Classes.myOrderItemCover}>
          <Link to={`/my-orders/${v.pk}`}>
            <button className={Classes.viewBTN}>View</button>
          </Link>
        </div>
      </div>
    ));

  const mappedLoading = Array(6)
    .fill("s")
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Orders - Passward Logistics </title>
        <meta name="og:title" content="Orders - Passward Logistics" />
        <meta name="og:description" content="Orders - Passward Logistics" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: "0px 20px",
          boxSizing: "border-box",
          width: "100%",
          filter: "blur(var(--body-blur,0px))",
        }}
      >
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Orders" />
          <div className={Classes.subNavCover}>
            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>Orders</h3>
              <div className={Classes.sideEachPageHeader}>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className={Classes.secondForm}
                >
                  <input
                    placeholder="Search Order"
                    onChange={(e) => setInputValue(e.target.value)}
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
              </div>
            </div>
          </div>

          {/* main orderSection */}
          <div className={Classes.cardCover}>
            <div className={Classes.mappedStoresCover}>
              <div className={Classes.cardListingCover}>
                {mappedOrders.length > 0 && (
                  <div className={Classes.cardHeader}>
                    <strong style={{ marginRight: "auto" }}>Order ID</strong>
                    <strong style={{ marginRight: "auto" }}>Products</strong>
                    <strong style={{ marginRight: "auto" }}>Date</strong>
                    {/* <strong>Payment Status</strong> */}
                    <strong>Total</strong>
                    <strong>Payment Method</strong>
                    <strong>Order Status</strong>
                    <strong>Action</strong>
                  </div>
                )}
                {loading ? (
                  mappedLoading
                ) : mappedOrders.length > 0 ? (
                  mappedOrders
                ) : (
                  <div className={Classes.coverEmptyResources}>
                    You do not have any orders yet
                  </div>
                )}
              </div>
            </div>
          </div>

          {mappedOrders.length > 0 && (
            <div
              style={{ display: "flex", marginBottom: "30px", width: "100%" }}
            >
              <div className={Classes.showingCover}>
                <select
                  onChange={onChangeHandler}
                  className={Classes.showingSelectCover}
                >
                  <option>Showing 1</option>
                  <option defaultValue selected>
                    Showing 10
                  </option>
                  <option>Showing 25</option>
                  <option>Showing 50</option>
                  <option>Showing 100</option>
                </select>
              </div>

              <ReactPaginate
                ref={refReactPaginate}
                breakLabel="..."
                nextLabel={<Left style={{ transform: "rotate(180deg)" }} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<Left />}
                className={Classes.paginationClass}
                renderOnZeroPageCount={null}
                disabledClassName={Classes.disabledClassName}
              />
            </div>
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default Orders;

//write a function to add an array
