// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_mainBackgroundCover__nrXoT {\n  position: fixed;\n  width: 100%;\n  height: calc(100%  + 100px);\n  z-index: 100;\n  background-color: #00003396;\n  top: -100px;\n  left: 0px;\n}\n\n.modal_mainModal__MDjp1 {\n  width: 600px;\n  border-radius: 8px;\n  padding: 1.4em 1.4em 1.4em  1.4em ;\n  /* height: 700px; */\n  max-width: 90%;\n  box-sizing: border-box;\n  background-color: #fff;\n  position: fixed;\n  top: 50%;\n  max-height: 600px;\n  overflow: hidden;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 1000;\n}\n\n.modal_AiOutlineClose__XpJmf{\n  fill: #7f7f7f;\n  width: max-content;\n  cursor: pointer;\n  margin-left: auto;\n  display: flex;\n}", "",{"version":3,"sources":["webpack://./src/component/modal/modal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,2BAA2B;EAC3B,YAAY;EACZ,2BAA2B;EAC3B,WAAW;EACX,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,kCAAkC;EAClC,mBAAmB;EACnB,cAAc;EACd,sBAAsB;EACtB,sBAAsB;EACtB,eAAe;EACf,QAAQ;EACR,iBAAiB;EACjB,gBAAgB;EAChB,SAAS;EACT,gCAAgC;EAChC,aAAa;AACf;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,aAAa;AACf","sourcesContent":[".mainBackgroundCover {\n  position: fixed;\n  width: 100%;\n  height: calc(100%  + 100px);\n  z-index: 100;\n  background-color: #00003396;\n  top: -100px;\n  left: 0px;\n}\n\n.mainModal {\n  width: 600px;\n  border-radius: 8px;\n  padding: 1.4em 1.4em 1.4em  1.4em ;\n  /* height: 700px; */\n  max-width: 90%;\n  box-sizing: border-box;\n  background-color: #fff;\n  position: fixed;\n  top: 50%;\n  max-height: 600px;\n  overflow: hidden;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 1000;\n}\n\n.AiOutlineClose{\n  fill: #7f7f7f;\n  width: max-content;\n  cursor: pointer;\n  margin-left: auto;\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainBackgroundCover": "modal_mainBackgroundCover__nrXoT",
	"mainModal": "modal_mainModal__MDjp1",
	"AiOutlineClose": "modal_AiOutlineClose__XpJmf"
};
export default ___CSS_LOADER_EXPORT___;
