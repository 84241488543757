import React, { useEffect, useState } from "react";
import Classes from "./dashboard.module.css";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import axios from "axios";
import { Months } from "../../data/monthsJson";

const RecentOrder = () => {
  const [loading, setLoading] = useState(false);
  const [incomeHistory, setIncomeHistory] = useState([]);
  const [orders, setOrders] = useState([]);

  const getIncomeHistory = async (month) => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASEURL}/logistic/wallet/income?month=${month}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.getItem("a_tk")}`,
          },
        }
      );
      setLoading(false);
      console.log(request?.data);
      // FIXME: Add pagination
      setIncomeHistory(request?.data?.data?.income_history);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecentOrder = async (month) => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASEURL}/logistic/wallet/recent-orders?month=${month}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.getItem("a_tk")}`,
          },
        }
      );
      setLoading(false);
      setOrders(request.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIncomeHistory(Months[new Date().getMonth()].name);
    fetchRecentOrder(Months[new Date().getMonth()].name);
  }, []);

  console.log(orders);
  const mappedOrders = orders.map((v, i) => (
    <div key={i} className={Classes.mappedOrders}>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}> Order ID: </strong>
        {v.order_code}
      </div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Products: </strong>
        {v?.product_qty}
      </div>
      <div className={Classes.myOrderItemCover}>{v.order_placed_on}</div>
      <div className={Classes.myOrderItemCover}>
        ₦{v?.product_qty * v?.product_discount_price}
      </div>
      <div className="md:absolute"></div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Payment Method: </strong>
        {v?.order.payment_method}
      </div>

      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Order Status: </strong>

        <button
          style={{ backgroundColor: "#ff6251" }}
          className={Classes.trueVisibilityCover}
        >
          {v?.delivery_status.toLowerCase()}
        </button>
      </div>
      <div className={Classes.myOrderItemCover}>
        <Link to={`/my-orders/${v.pk}`}>
          <button className={Classes.viewBTN}>View</button>
        </Link>
      </div>
    </div>
  ));

  const mappedLoading = Array(3)
    .fill("s")
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  const mappedIncome = incomeHistory.map((v, i) => (
    <>
      <tr
        key={i}
        className={`${
          i % 2 !== 0 && "bg-gray-100"
        } md:table-row hidden text-sm`}
      >
        <td className={`py-5 `}>{v?.code}</td>

        <td className={``}>{v?.amount}</td>

        <td className={``}>{v?.shipping_fee}</td>

        <td className={``}>{v?.store_name}</td>

        <td className={``}>{v?.date}</td>
      </tr>
      <div
        className={`p-5 space-y-3 ${
          i % 2 !== 0 && "bg-gray-200"
        } relative md:hidden`}
      >
        <p className="font-bold text-[#6c757d]">
          Amount: <span className="font-light"> {v?.amount}</span>
        </p>

        <p className="font-bold text-[#6c757d]">
          Shipping Fee: <span className="font-light"> {v?.shipping_fee}</span>
        </p>

        <p className="font-bold text-[#6c757d]">
          Store Name: <span className="font-light">{v?.store_name}</span>
        </p>

        <p className="font-bold text-[#6c757d]">
          Date: <span className="font-light">{v.date}</span>
        </p>
      </div>
    </>
  ));

  return (
    <>
      <div className={Classes.RecentOrderCover}>
        <div style={{ position: "relative" }} className={Classes.headerSection}>
          <strong className={Classes.strongHeaderHERE}>Recent Orders</strong>
          <select
            className={Classes.selectSectionHere}
            onChange={(e) => fetchRecentOrder(e.target.value)}
          >
            {Months.map((v, i) => (
              <option key={i} selected={i === new Date().getMonth()}>
                {v.name}
              </option>
            ))}
          </select>
        </div>
        <div className={Classes.cardHeader}>
          <strong style={{ marginRight: "auto" }}>Order ID</strong>
          <strong style={{ marginRight: "auto" }}>Products</strong>
          <strong style={{ marginRight: "auto" }}>Date</strong>
          <strong>Total</strong>
          <strong>Payment Method</strong>
          <strong>Order Status</strong>
          <strong>Action</strong>
        </div>
        {loading ? (
          mappedLoading
        ) : mappedOrders.length < 1 ? (
          <div className={Classes.noIncome}>You have no orders </div>
        ) : (
          mappedOrders
        )}
      </div>

      <div className="mt-5">
        <div style={{ position: "relative" }} className={Classes.headerSection}>
          <strong className={Classes.strongHeaderHERE}>Income History</strong>
          <select
            className={Classes.selectSectionHere}
            onChange={(e) => getIncomeHistory(e.target.value)}
          >
            {Months.map((v, i) => (
              <option key={i} selected={i === new Date().getMonth()}>
                {v.name}
              </option>
            ))}
          </select>
        </div>

        <table className="w-full hidden md:table text-center">
          {mappedOrders.length > 0 && (
            <>
              <thead className="font-bold w-full  text-[#6c757d]">
                <tr className="bg-gray-200 w-full md:text-[1rem] text-xs">
                  <th className="py-5 ">Order Code</th>
                  <th className="">Item Amount</th>
                  <th className="">Shipping Fee</th>
                  <th className="py-5 ">From</th>
                  <th className="">Date</th>
                </tr>
              </thead>
              <tbody className="w-full ">
                {loading ? (
                  mappedLoading
                ) : mappedIncome.length < 1 ? (
                  <div className={Classes.noIncome}>
                    You have no income history{" "}
                  </div>
                ) : (
                  mappedIncome
                )}
              </tbody>
            </>
          )}
        </table>
        <div className="md:hidden">
          {loading ? (
            mappedLoading
          ) : mappedIncome.length < 1 ? (
            <div className={Classes.noIncome}>You have no income history </div>
          ) : (
            mappedIncome
          )}
        </div>

        {/* <ReactPaginate
                ref={refReactPaginate}
                breakLabel="..."
                nextLabel={<Left style={{ transform: "rotate(180deg)" }} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<Left />}
                className={Classes.paginationClass}
                renderOnZeroPageCount={null}
                disabledClassName={Classes.disabledClassName}
              /> */}
      </div>
    </>
  );
};

export default RecentOrder;
