import React, { useState, useContext } from "react";
import Classes from "./verificationPage.module.css";
import SemiLogo from "../../asset/logo_semi.png";
import RightLogo from "../../asset/star.png";
import Logo from "../../asset/logo.png";
import Button from "../../component/button/button";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../App";
import { useParams } from "react-router-dom";
import Input from "../../component/input/input";
import axios from "axios";
import { States } from "../../data/stateJson";

const VerificationPage = () => {
  const CTX = useContext(MainContext);
  const params = useParams();
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const [vehicleIMG, setVehicleIMG] = useState({});
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedStates, setSelectedStates] = useState("Abia");
  const [inputs, setInputs] = useState({});
  const [showPhoneMSG, setShowPhoneMSG] = useState(false);
  const [showPassMSG, setShowPassMSG] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  //   the main button handler here
  const submitHandler = async (e) => {
    e.preventDefault();
    if (selectedStates === "" || !selectedStates)
      return toast("select a state");
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append(
        "vehicle",
        vehicleIMG?.target?.files[Object.keys(vehicleIMG?.target?.files)]
      );
      formData.append("password", inputs.password);
      formData.append("first_name", inputs.first_name);
      formData.append("last_name", inputs.last_name);
      formData.append("state", selectedStates);
      formData.append("phone", inputs.phone_number);
      formData.append("company_name", inputs.company_name);
      formData.append("cac", inputs.cac);
      formData.append("city", inputs.city);
      formData.append("address", inputs.address);
      formData.append("no_of_vehicles", inputs.no_of_vehicles);

      await axios({
        url: `${process.env.REACT_APP_BASEURL}/logistic/register/${params.email}`,
        data: formData,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLoading(false);
      navigate("/login");
      toast("Registration completed, Kindly login");
    } catch (error) {
      setLoading(false);
      if (error.response.data.status === 400) {
        console.log("error", error.response);
        return toast(error.response.data.message.email[0]);
      }
      toast("Check your internet");
    }
  };

  return (
    <div className={Classes.LoginFormCover}>
      <Helmet>
        <title>Complete Registration - Passward Logistic </title>
        <meta
          name="og:title"
          content="Complete Registration - Passward Logistic"
        />
        <meta
          name="og:description"
          content="Complete Registration - Passward Logistic"
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={Classes.relative}>
        <nav className={Classes.nav}>
          <Link to="/" onClick={() => CTX.closeLogin()}>
            <img src={Logo} className={Classes.logo} alt="Passward Logo" />
          </Link>
        </nav>
        <form
          onSubmit={submitHandler}
          autoComplete="off"
          className={Classes.formCover}
        >
          <h1 className={Classes.header}>Complete your registration</h1>
          <p className={Classes.semiNoteHere}>
            Complete your registration and set up your account with us
          </p>

          {/* {register && ( */}
          <div className={Classes.main_width}>
            <div className={Classes.gridTwo}>
              <Input
                type="input"
                required={true}
                value={inputs?.first_name}
                onChange={(e) =>
                  setInputs({ ...inputs, first_name: e.target.value })
                }
                labelStyle={{ color: "#000033" }}
                label="First Name"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
                placeholder="First Name"
                marb
              />

              <Input
                type="input"
                required={true}
                labelStyle={{ color: "#000033" }}
                label="Last Name"
                value={inputs?.last_name}
                onChange={(e) =>
                  setInputs({ ...inputs, last_name: e.target.value })
                }
                placeholder="Last Name"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
                marb
              />

              <Input
                type="input"
                required={true}
                labelStyle={{ color: "#000033" }}
                label="Company Name"
                value={inputs?.company_name}
                onChange={(e) =>
                  setInputs({ ...inputs, company_name: e.target.value })
                }
                placeholder="Company Name"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
              />

              <div>
                <Input
                  type="input"
                  required={true}
                  labelStyle={{ color: "#000033" }}
                  label="Phone Number"
                  value={inputs?.phone_number}
                  onChange={(e) => {
                    setInputs({ ...inputs, phone_number: e.target.value });
                    if (e.target.value.length === 11) {
                      return setShowPhoneMSG(false);
                    } else {
                      return setShowPhoneMSG(true);
                    }
                  }}
                  placeholder="Phone Number"
                  style={{
                    backgroundColor: "#fff",
                    height: "50px",
                    border: "1px solid #ff0066",
                  }}
                />
                {showPhoneMSG && (
                  <>
                    {inputs?.phone_number?.length > 1 && (
                      <p className={Classes.must_Contain}>
                        Phone number must be 11 digits
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className={Classes.gridTwo}>
              <Input
                type="select"
                required
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
                labelStyle={{ color: "#000033" }}
                options={["Choose state"].concat(
                  States.map((v) => {
                    return v.name;
                  })
                )}
                onChange={(e) => setSelectedStates(e.target.value)}
                label="State"
                marb
              />

              <Input
                type="input"
                required={true}
                labelStyle={{ color: "#000033" }}
                label="CAC"
                value={inputs?.cac}
                onChange={(e) => setInputs({ ...inputs, cac: e.target.value })}
                placeholder="CAC"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
                marb
              />

              <Input
                type="input"
                required={true}
                labelStyle={{ color: "#000033" }}
                label="City"
                value={inputs?.city}
                onChange={(e) => setInputs({ ...inputs, city: e.target.value })}
                placeholder="City"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
                marb
              />

              <Input
                type="input"
                required={true}
                labelStyle={{ color: "#000033" }}
                label="Address"
                value={inputs?.address}
                onChange={(e) =>
                  setInputs({ ...inputs, address: e.target.value })
                }
                placeholder="Address"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
                marb
              />

              <Input
                type="input"
                required={true}
                labelStyle={{ color: "#000033" }}
                label="No of Vehicles"
                value={inputs?.no_of_vehicles}
                onChange={(e) =>
                  setInputs({ ...inputs, no_of_vehicles: e.target.value })
                }
                placeholder="No of Vehicles"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
              />
              <Input
                type="input"
                // onBlur={onBlurImageHandler}
                inputType="file"
                onChange={(e) => setVehicleIMG(e)}
                accept="image/png, image/gif, image/jpeg"
                labelStyle={{ color: "#000033" }}
                label="Vehicle"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  display: "flex",
                  alignItem: "center",
                  border: "1px solid #ff0066",
                }}
                required
                // options={States.map(v => v.name)}
              />
            </div>
          </div>

          <div className={Classes.main_width} style={{ marginTop: "8px" }}>
            <Input
              type="input"
              required={true}
              value={inputs?.password}
              onChange={(e) => {
                setInputs({ ...inputs, password: e.target.value });
                if (re.test(e.target.value)) {
                  return setShowPassMSG(false);
                } else {
                  return setShowPassMSG(true);
                }
              }}
              labelStyle={{ color: "#000033" }}
              placeholder="* * * * * * *"
              showPassword={() => setShowPassword(!showPassword)}
              inputType={showPassword ? "text" : "password"}
              label="Password"
              style={{
                backgroundColor: "#fff",
                height: "50px",
                border: "1px solid #ff0066",
              }}
            />

            {showPassMSG && (
              <>
                {inputs?.password?.length > 1 && (
                  <p className={Classes.must_Contain}>
                    Password must contain an Uppercase, Lowercase and Symbol
                  </p>
                )}
              </>
            )}
          </div>

          <div className={Classes.main_width} style={{ marginTop: "18px" }}>
            <Button
              text="Submit"
              style={{ width: "130px", height: "40px", margin: "auto" }}
              loading={loading}
            />
          </div>

          <Link to="/login" style={{ color: "inherit" }}>
            <div className={Classes.resendCode}>Back To Login</div>
          </Link>
        </form>
        <img alt=" " src={SemiLogo} className={Classes.topRightLogo} />
        <img alt=" " src={RightLogo} className={Classes.rightLogo} />
        <img alt=" " src={RightLogo} className={Classes.leftLogo} />
        <img alt=" " src={RightLogo} className={Classes.topLeftLogo} />
        <img alt=" " src={SemiLogo} className={Classes.semiLogo} />
      </div>
    </div>
  );
};

export default VerificationPage;
