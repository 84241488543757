import React, { useState, useEffect, useRef, useContext } from "react";
import { MainContext } from "../../App";
import Products from "./withdrawal";
import { Months } from "../../data/monthsJson";

const EventProducts = () => {
  const [itemsPerPage, setItemsPerPage] = useState("10");
  const [monthly_income, setMonthly_income] = useState("");
  const CTX = useContext(MainContext);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [withdrawal, setWithdrawal] = useState({
    monthly_balance: { month: "July", amount: 0 },
    wallet_balance: 0,
    withdraw_history: [],
  });
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({});
  const [category, setCategory] = useState([]);
  const refReactPaginate = useRef();

  const getAllProducts = async () => {
    // setTimeout(() => {
    //   setProducts(data);
    //   setLoading(false);
    // }, 4000);
    // setLoading(true);

    // setLoading(true);
    const fetched = await fetch(
      `${process.env.REACT_APP_BASEURL}/logistic/wallet/withdraw/history`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
      }
    );
    const json = await fetched.json();

    setLoading(false);
    // setProducts(json.data.withdraw_history);
    setWithdrawal(json?.data);
    // console.log('json.data => ', json.data);
    // setMonthly_income(json.data.monthly_income.amount);
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    if (withdrawal.withdraw_history.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = withdrawal.withdraw_history.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(withdrawal.withdraw_history.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, withdrawal]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset =
      (event.selected * itemsPerPage) % withdrawal.withdraw_history.length;
    setItemOffset(newOffset);
  };

  const onChangeHandler = (e) => {
    setItemsPerPage(e.target.value.split(" ")[1]);
  };

  const selectOnChangeHandler = async (e) => {
    setLoading(true);

    const fetched = await fetch(
      `${process.env.REACT_APP_BASEURL}/logistic/wallet/withdraw/history?month=${e}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
      }
    );
    const json = await fetched.json();

    setLoading(false);
    setWithdrawal(json?.data);
    setMonthly_income(json?.data?.monthly_income?.amount);
  };

  useEffect(() => {
    selectOnChangeHandler(Months[new Date().getMonth()].name);
  }, []);

  return (
    <Products
      showModal={showModal}
      setShowModal={setShowModal}
      loading={loading}
      handlePageClick={handlePageClick}
      pageCount={pageCount}
      currentItems={currentItems}
      withdrawal={withdrawal}
      pageNum={pageNum}
      refReactPaginate={refReactPaginate}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      inputs={inputs}
      category={category}
      onChangeHandler={onChangeHandler}
      selectOnChangeHandler={selectOnChangeHandler}
      monthly_income={monthly_income}
      setInputs={setInputs}
      setCategory={setCategory}
    />
  );
};

export default EventProducts;
