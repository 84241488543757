import React, { useEffect, useState, useRef, useContext } from "react";
import { MainContext } from "../../App";
import Wallet from "./income";

const EventWallet = () => {
  const [itemsPerPage, setItemsPerPage] = useState("10");
  const CTX = useContext(MainContext);

  const [showModal, setShowModal] = useState(false);
  const [transaction, setTransaction] = useState({ income_history: [] });
  const [monthly_income, setMonthly_income] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const refReactPaginate = useRef();

  const getAllOrder = async () => {
    setLoading(true);
    const fetched = await fetch(
      `${process.env.REACT_APP_BASEURL}/logistic/wallet/income`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
      }
    );
    const json = await fetched.json();

    setLoading(false);
    setTransaction(json.data);
  };

  useEffect(() => {
    getAllOrder();
  }, []);

  useEffect(() => {
    if (transaction?.income_history.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;

    const allContent = transaction.income_history.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(transaction.income_history.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, transaction]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset =
      (event.selected * itemsPerPage) % transaction.income_history.length;
    setItemOffset(newOffset);
  };

  const selectOnChangeHandler = async (e) => {
    setLoading(true);
    const fetched = await fetch(
      `${process.env.REACT_APP_BASEURL}/logistic/wallet/income?month=${
        e.target.value.split(" ")[0]
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
      }
    );
    const json = await fetched.json();
    setLoading(false);
    setTransaction(json.data);
    setMonthly_income(json?.data?.monthly_income?.amount);
  };

  const onChangeHandler = (e) => {
    setItemsPerPage(e.target.value.split(" ")[1]);
  };

  return (
    <Wallet
      loading={loading}
      currentItems={currentItems}
      pageCount={pageCount}
      pageNum={pageNum}
      refReactPaginate={refReactPaginate}
      handlePageClick={handlePageClick}
      transaction={transaction}
      monthly_income={monthly_income}
      showModal={showModal}
      selectOnChangeHandler={selectOnChangeHandler}
      setShowModal={setShowModal}
      onChangeHandler={onChangeHandler}
    />
  );
};

export default EventWallet;
