import React, { useReducer, useState } from "react";
import { Toaster } from "react-hot-toast";
import AllRoutes from "./routes";
const initialState = {
  url: null,
  user: null,
  token: null,
  openLogin: () => {},
  closeLogin: () => {},
  checkLogin: () => {},
  setToken: () => {},
  removeSideBar: () => {},
  logout: () => {},
  setUser: () => {},
  setStats: () => {},
  stats: null,
  sidebar: false,
};
function App() {
  const url = process.env.REACT_APP_BASEURL;
  const [token, setToken] = useState(sessionStorage.getItem("a_tk") || "");
  const [stats, setStats] = useState(
    JSON.parse(sessionStorage.getItem("stats")) || {}
  );
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("user")) || {}
  );

  const openLogin = () => {
    document.body.setAttribute("data-side-login-mode", "open");
  };

  const closeLogin = () => {
    document.body.removeAttribute("data-side-login-mode", "open");
  };

  const removeSideBar = () => {
    document.body.removeAttribute("data-side-navigation-mode");
    document.body.classList.remove("fade-effect");
  };

  const checkLogin = () => {
    if (token.length < 3) {
      openLogin();
    } else {
      return;
    }
  };

  const setTokenHandler = (_token) => {
    setToken(_token);
    closeLogin();
    sessionStorage.setItem("a_tk", _token);
  };

  const setStatsHandler = (_payload) => {
    sessionStorage.setItem("stats", JSON.stringify(_payload));
    setStats(_payload);
  };

  const setUserHandler = (_user) => {
    setUser(_user);
    sessionStorage.setItem("user", JSON.stringify(_user));
  };

  const logoutHandler = () => {
    sessionStorage.clear();
    setToken("");
    setUser({});
  };

  const Reducer = (state, action) => {
    switch (action.type) {
      case "CLOSE_NAV":
        return { ...state, sidebar: action.payload };
      case "OPEN_NAV":
        return { ...state, sidebar: action.payload };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(Reducer, initialState);
  const closeNav = () => {
    dispatch({
      type: "CLOSE_NAV",
      payload: false,
    });
  };

  const openNav = () => {
    dispatch({
      type: "OPEN_NAV",
      payload: true,
    });
  };
  return (
    <MainContext.Provider
      value={{
        url,
        openLogin,
        closeLogin,
        checkLogin,
        token,
        removeSideBar,
        user,
        setToken: setTokenHandler,
        setUser: setUserHandler,
        logout: logoutHandler,
        setStats: setStatsHandler,
        stats,
        closeNav,
        sidebar: state.sidebar,
        openNav,
      }}
    >
      <Toaster
        position="bottom-left"
        toastOptions={{
          style: {
            background: "#ff0066",
            borderLeft: "5px solid #003",
            fontSize: "14px",
            color: "#fff",
          },
        }}
      />
      <AllRoutes />
    </MainContext.Provider>
  );
}

export default App;

export const MainContext = React.createContext(initialState);

// I need logistics name and phone number under viewing eachOrder page
// also need list of orders in the same viewing eachOrder page

// under withdrawal page => no withdrawal history example in postman
