import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Classes from './modal.module.css';

  
  export const Modal = ({show, children, close, showClose=true, top="40%",className }) => {
  return (
    <>
      <BGround show={show} close={close} />
      <div style={{top: show?top:"-200%"}} className={`transition-all ${className}  max-w-[926px] ${Classes.mainModal}`} >
        {showClose && <AiOutlineClose className={Classes.AiOutlineClose} onClick={close} />}
        {children}
        
        </div>
    </>
  );
};

export const BGround = ({show, close}) => {
  return <>{show && <div onClick={close} className={Classes.mainBackgroundCover}></div>}</>;
};
