import React, { useContext, useState } from "react";
import axios from "axios";
import Forgot from "./forgot";
import { MainContext } from "../../App";
import toast from "react-hot-toast";

const EventForgot = () => {
  const CTX = useContext(MainContext);
  const [msg, setMsg] = useState("");
  const [selectedStates, setSelectedStates] = useState("Abia");
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    try {
      const url = await axios.post(
        `${process.env.REACT_APP_BASEURL}/logistic/password/forgot`,
        {
          email: inputs.email,
        }
      );
      setLoading(false);
      toast(url.data.data.password);
    } catch (error) {
      console.log("error", error.response);
      setLoading(false);

      if (error.response) {
        toast(error?.response?.data?.data.email);
        return;
      } else {
        toast("Check your internet");
        return;
      }
    }
  };

  return (
    <Forgot
      inputs={inputs}
      setInputs={setInputs}
      setSelectedStates={setSelectedStates}
      selectedStates={selectedStates}
      msg={msg}
      setMsg={setMsg}
      onSubmitHandler={onSubmitHandler}
      loading={loading}
      CTX={CTX}
      setLoading={setLoading}
    />
  );
};

export default EventForgot;
