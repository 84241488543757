import React, { useContext } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { MainContext } from "../App";
import EventForgot from "../pages/forgotPassword/forgotLogin";
import Login from "../pages/login/eventLogin";
import EventReset from "../pages/resetPassword/eventReset";
import VerificationPage from "../pages/verificationPage/verificationPage";
import Verify from "../pages/verify/verify";
const PubRoutes=()=>{
  const {token}=useContext(MainContext)
  return(
    !token?<Outlet/>:<Navigate to={'/dashboard'}/>
  )
}
const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PubRoutes/>}>
      <Route path="login" element={<Login />} />
      <Route
        path="complete-registration/:email"
        element={<VerificationPage />}
      />
      <Route path="verify" exact element={<Verify />} />
      <Route path="forgot-password" exact element={<EventForgot />} />
      <Route path="reset-password/:token" element={<EventReset />} />
      </Route>
    </Routes>
  );
};
export default PublicRoutes;
