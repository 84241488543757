import React, { Suspense, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineLock, AiOutlineUser } from "react-icons/ai";

import Card from "../../component/card/card";
import Button from "../../component/button/button";
import Classes from "./withdrawalSettings.module.css";
import RightSide from "./rightSide";
import ChangePass from "./changePass";
import { MainContext } from "../../App";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import {
  EachPageHeader,
  PageHeader,
} from "../../component/layout/eachPageHeader/eachPageHeader";
import joinClasses from "../../utils/joinClasses";

const WithdrawalSettings = () => {
  const CTX = useContext(MainContext);
  const [password, setPassword] = useState(false);
  const [agent, setAgent] = useState(false);
  const [showImage, setShowImage] = useState(false);

  // console.log("useLocation() => ",  useLocation().search)

  return (
    <>
      {/* change password modal */}
      <ChangePass
        show={password}
        close={() => setPassword(false)}
        showAgent={agent}
        showImage={showImage}
        closeImage={() => setShowImage(false)}
        closeAgent={() => setAgent(false)}
      />

      <Helmet>
        <title>Settings - Passward Logistics </title>
        <meta name="og:title" content="Settings - Passward Logistics" />
        <meta name="og:description" content="Settings - Passward Logistics" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={Classes.flexSection}>
        <div
          onClick={CTX.removeSideBar}
          style={{
            padding: "0px 20px",
            boxSizing: "border-box",
            width: "100%",
            filter: "blur(var(--body-blur,0px))",
          }}
        >
          <Suspense fallback={<LazyLoading />}>
            {/* nav section here is just the top header side */}
            <EachPageHeader first="Home" second="Settings" />
            <PageHeader name="Settings" />
            <div className={Classes.gridTwoFiles}>
              <div className={Classes.firstCardCover}>
                <div className={Classes.pageHeader}>Store Settings</div>
                <Card>
                  <div className={Classes.paddingCard}>
                    {!CTX.user.photo ? (
                      <div className={Classes.SidebarHeaderRoundCover}>
                        <AiOutlineUser size="2.3em" fill="#fff" />
                      </div>
                    ) : (
                      <img
                        className={joinClasses(Classes.avatar, "object-cover")}
                        src={CTX.user.photo}
                        alt="avatar"
                      />
                    )}
                    <strong className={Classes.profileName}>
                      {CTX.user.first_name} {CTX.user.last_name}
                    </strong>

                    <div style={{ width: "max-content", margin: "auto" }}>
                      <Button
                        bgColor="#f1556c"
                        style={{ backgroundColor: "#000033", border: "none" }}
                        text="Change Password"
                        onClick={() => setPassword(true)}
                        svg={<AiOutlineLock style={{ marginRight: "8px" }} />}
                      />
                    </div>

                    <div className={Classes.textLeft}>
                      <p className="text-muted mb-2 font-13">
                        <strong>Full Name:</strong>{" "}
                        <span
                          className={Classes.ml_2}
                          style={{ textTransform: "capitalize" }}
                        >
                          {CTX.user.first_name} {CTX.user.last_name}
                        </span>
                      </p>

                      <p className="text-muted mb-2 font-13">
                        <strong>Phone Number:</strong>
                        <span className={Classes.ml_2}>{CTX.user.phone}</span>
                      </p>

                      <p className="text-muted mb-2 font-13">
                        <strong>Email Address:</strong>{" "}
                        <span className={Classes.ml_2}>{CTX.user.email}</span>
                      </p>

                      <p className="text-muted mb-1 font-13">
                        <strong>State:</strong>{" "}
                        <span className={Classes.ml_2}>{CTX.user.state}</span>
                      </p>
                    </div>
                  </div>
                </Card>
              </div>

              <RightSide />
            </div>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default WithdrawalSettings;
