import React, { useContext, useState } from "react";
import Classes from "./nav.module.css";
import { ReactComponent as Logo } from "../../../asset/logo.svg";
import { ReactComponent as Slogo } from "../../../asset/small-logo.svg";
import { ReactComponent as Down } from "../../../asset/down.svg";
import { AiOutlineLogout, AiOutlineMenu, AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MainContext } from "../../../App";
import { Modal } from "../../modal/modal";
import Button from "../../button/button";

const Nav = () => {
  const CTX = useContext(MainContext);
  const [showModal, setShowModal] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const handleAllSideBarMenu = () => {
    // document.body.setAttribute('data-side-navigation-mode', 'open');
    // document.body.classList.add('fade-effect');
    CTX.openNav();
  };

  document.body.addEventListener("click", () => {
    setShowDropDown(false);
  });

  return (
    <nav className={`${Classes.navCoverMain} fixed top-0 z-30`}>
      <div className={Classes.miniNav}>
        <AiOutlineMenu
          onClick={handleAllSideBarMenu}
          size="1.5em"
          className={Classes.AiOutlineMenu}
        />
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to="/dashboard"
        >
          {" "}
          <Logo className={Classes.Logo} />
        </Link>
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to="/dashboard"
        >
          {" "}
          <Slogo className={Classes.Slogo} />
        </Link>

        <div
          onClick={(e) => e.stopPropagation()}
          className={Classes.rightSideNavDesign}
        >
          {CTX.token && (
            <div className="relative">
              <div
                onClick={() => setShowDropDown(!showDropDown)}
                className={`flex ${Classes.profileRightSideNav}`}
              >
                <AiOutlineUser size="1.3em" style={{ fill: "#000033" }} />
                <Down style={{ width: "15px", marginTop: "3px" }} />
              </div>
              <div
                className={`absolute left-[-100%] md:left-0 overflow-hidden text-gray-500  hover:bg-[#f13782] hover:text-white flex shadow-lg ${
                  showDropDown ? "h-[40px]" : "h-0"
                } transition-all top-[100%]`}
              >
                <div
                  className={`px-5 m-auto gap-2 items-center `}
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    setShowModal(!showModal);
                    setShowDropDown(!showDropDown);
                  }}
                >
                  <AiOutlineLogout size="1.3em" />
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Logout
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={showModal}
        close={() => {
          setShowModal(false);
          setShowDropDown(false);
        }}
      >
        <h3 className={Classes.confirmRemoval}>Log out of Passward</h3>
        <p className={Classes.removed}>
          This will log you out of your account, all your data will be saved.
          Continue to Log Out?
        </p>

        <div style={{ display: "flex" }}>
          <Button
            text="Yes, Log Out"
            style={{ width: "160px" }}
            onClick={() => {
              CTX.logout();
              setShowModal(false);
            }}
          />
          <div style={{ width: "20px" }}></div>
          <Button
            text="Don't Log Out"
            onClick={() => setShowModal(false)}
            style={{
              backgroundColor: "inherit",
              border: "2px solid #ff0066",
              color: "#ff0066",
              width: "160px",
            }}
          />
        </div>
      </Modal>
    </nav>
  );
};

export default Nav;
