import { useState } from "react";
import axios from "axios";
// import Loading from '../components/loading/loading';

export const GetUserStatistics = () => {
  const [loadingStatistics, setLoading] = useState(false);
  const [data, setData] = useState({});

  const getSta = async () => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASEURL}/logistic/wallet/overview`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.getItem("a_tk")}`,
          },
        }
      );
      setLoading(false);
      sessionStorage.setItem("stats", JSON.stringify(request.data.data));
      setData(request.data.data);
      // console.log("statistic request.data.data => ", request.data.data)
    } catch (error) {
      setLoading(false);
      // GetStatistics()
      // setMsg('Invalid login details');
      console.log(error);
    }
  };

  // useEffect(() => {
  //     GetStatistics()
  // }, [])

  return { loadingStatistics, data, getSta };
};

// HD2020/07289/1/CS
