import React, { useContext, useState, useRef } from "react";
import {
  AiOutlineUser,
  AiOutlineLogout,
  AiOutlineShopping,
  // AiOutlineShop,
  // AiOutlinePicLeft,
  AiOutlineSetting,
  AiOutlineWallet,
} from "react-icons/ai";
import Classes from "./sidebar.module.css";
import { ReactComponent as Left } from "../../../asset/left.svg";
// import {ReactComponent as Customer} from '../../../asset/customer.svg';
import { ReactComponent as Apps } from "../../../asset/apps.svg";
import { ReactComponent as USD } from "../../../asset/usd_circle.svg";
import { ReactComponent as Income } from "../../../asset/income.svg";
import { ReactComponent as Withdrawal } from "../../../asset/money_withdrawal.svg";
import { NavLink, Link } from "react-router-dom";
import { Modal } from "../../modal/modal";
import { MainContext } from "../../../App";
import Button from "../../button/button";
import joinClasses from "../../../utils/joinClasses";

const Sidebar = () => {
  const CTX = useContext(MainContext);
  const [showModal, setShowModal] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const onClickSettingHandler = (e) => {
    e.preventDefault();
    setShowSettings(!showSettings);
    setShowTransaction(false);
  };

  const onClickTransactionHandler = (e) => {
    e.preventDefault();
    setShowSettings(false);
    setShowTransaction(!showTransaction);
  };

  const sidebarRef = useRef(null);

  return (
    <aside
      ref={sidebarRef}
      className={`w-[290px] z-[30] lg:left-0 lg:top-[100px] ${
        !CTX.sidebar ? "left-[-300px]" : "left-0"
      } top-0 lg:block lg:h-[90%] h-full   ${Classes.mainSideBarSection}`}
    >
      <div className={Classes.SidebarMainCover}>
        <div
          className={joinClasses(Classes.SidebarHeaderCover, "flex flex-col")}
        >
          <div className={Classes.SidebarHeaderRoundCover}>
            {!CTX.user.photo ? (
              <AiOutlineUser size="2.3em" fill="#fff" />
            ) : (
              <img
                src={CTX.user.photo}
                alt="Profile"
                className={Classes.ProfileIMG}
              />
            )}
          </div>
          <h5 className={Classes.SidebarUsername}>
            {CTX.user.first_name} {CTX.user.last_name}
          </h5>
        </div>
        <div className={joinClasses(" flex-1 relative")}>
          <div
            className={joinClasses(
              "absolute w-full h-full top-0 left-0 overflow-y-auto !py-5",
              Classes.SidebarLinkCover
            )}
          >
            <EachLink
              svg={<Apps width="1.3em" />}
              name="Overview"
              to="/dashboard"
              onClick={CTX.closeNav}
            />
            {/* <EachLink svg={<AiOutlineUser size="1.3em" />} to="/profile" name="Profile" /> */}
            {/* <EachLink
            svg={<Store fill="#7f7f7f" width="1.3em" />}
            name="Stores"
            right={CTX.stats.stores}
            to="/stores"
          /> */}
            <EachLink
              svg={<AiOutlineShopping size="1.3em" />}
              name="Orders"
              right={CTX.stats.order}
              to="/my-orders"
              onClick={CTX.closeNav}
            />
            <EachLink
              svg={<AiOutlineWallet size="1.3em" />}
              name="Wallet"
              right={
                <Left
                  width="1.2em"
                  style={{ fill: "#7F7F7F", transform: "rotate(-90deg)" }}
                />
              }
              onClick={onClickTransactionHandler}
            />
            <div
              style={{ height: showTransaction ? "max-content" : "0px" }}
              className={Classes.semiLinkCover}
            >
              <EachLink
                svg={<Income size="1.3em" />}
                style={{ margin: "0px ", padding: "0px" }}
                name="Income"
                to="/income"
                onClick={CTX.closeNav}
              />
              <EachLink
                svg={<Withdrawal size="1.3em" />}
                style={{
                  margin: "10px 0px 0px 0px ",
                  padding: "10px 0px 0px 0px ",
                }}
                name="Withdrawal"
                to="/withdrawal"
                onClick={CTX.closeNav}
              />
            </div>

            <EachLink
              svg={<AiOutlineSetting size="1.3em" />}
              name="Settings"
              style={{
                marginTop: !showTransaction && "0px",
                paddingTop: !showTransaction && "5px",
              }}
              right={
                <Left
                  width="1.2em"
                  style={{ fill: "#7F7F7F", transform: "rotate(-90deg)" }}
                />
              }
              onClick={onClickSettingHandler}
            />
            <div
              style={{ height: showSettings ? "max-content" : "0px" }}
              className={Classes.semiLinkCover}
            >
              {/* <EachLink
              svg={<AiOutlineWallet size="1.3em" />}
              style={{margin: '0px ', padding: '0px'}}
              name="Account"
              to="/settings?type=account"
            /> */}
              <Link
                to="/settings?type=account"
                style={{ textDecoration: "none" }}
                onClick={CTX.closeNav}
              >
                <div
                  style={{ margin: "0px ", padding: "0px" }}
                  className={Classes.EachLinkCover}
                >
                  <AiOutlineWallet size="1.3em" />
                  <h5>Account</h5>
                  <strong></strong>
                </div>
              </Link>
              {/* <EachLink
              svg={<USD size="1.3em" />}
              style={{
                margin: '10px 0px 0px 0px ',
                padding: '10px 0px 0px 0px ',
              }}
              name="Withdrawal"
              to="/settings?type=withdrawal"
            /> */}
              <Link
                to="/settings?type=withdrawal"
                style={{ textDecoration: "none" }}
                onClick={CTX.closeNav}
              >
                <div
                  style={{
                    margin: "10px 0px 0px 0px ",
                    padding: "10px 0px 0px 0px ",
                  }}
                  className={Classes.EachLinkCover}
                >
                  <USD size="1.3em" />
                  <h5>Withdrawal</h5>
                  <strong></strong>
                </div>
              </Link>
            </div>

            <EachLink
              svg={<AiOutlineLogout size="1.3em" />}
              onClick={(e) => {
                e.preventDefault();
                setShowModal(!showModal);
              }}
              name="Log Out"
            />
          </div>
        </div>
      </div>
      <Modal show={showModal} close={() => setShowModal(false)}>
        <h3 className={Classes.confirmRemoval}>Log out of Passward</h3>
        <p className={Classes.removed}>
          This will log you out of your account, all your data will be saved.
          Continue to Log Out?
        </p>

        <div style={{ display: "flex" }}>
          <Button
            text="Yes, Log Out"
            style={{ width: "160px" }}
            onClick={() => {
              CTX.logout();
              setShowModal(false);
            }}
          />
          <div style={{ width: "20px" }}></div>
          <Button
            text="Don't Log Out"
            onClick={() => setShowModal(false)}
            style={{
              backgroundColor: "inherit",
              border: "2px solid #ff0066",
              color: "#ff0066",
              width: "160px",
            }}
          />
        </div>
      </Modal>
    </aside>
  );
};

export default Sidebar;

const EachLink = ({ onClick, name, svg, right, to = "/", style }) => (
  <>
    {to ? (
      <NavLink to={to} onClick={onClick} style={{ textDecoration: "none" }}>
        <div style={style} className={Classes.EachLinkCover}>
          {svg}
          <h5>{name}</h5>
          <strong>{right}</strong>
        </div>
      </NavLink>
    ) : (
      <div style={style} className={Classes.EachLinkCover}>
        {svg}
        <h5>{name}</h5>
        <strong>{right}</strong>
      </div>
    )}
  </>
);
