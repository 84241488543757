import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { Modal } from "../../component/modal/modal";
import Input from "../../component/input/input";
import Button from "../../component/button/button";
import Classes from "./withdrawalSettings.module.css";
import { MainContext } from "../../App";

const ChangePass = ({
  show,
  close,
  showAgent,
  closeAgent,
  showImage,
  closeImage,
}) => {
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const CTX = useContext(MainContext);
  const [showPassMSG, setShowPassMSG] = useState(false);
  const [showComPassMSG, setShowComPassMSG] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showExistPassword, setShowExistPassword] = useState(false);
  const [anoShowPassword, setAnoShowPassword] = useState(false);
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

  const onSubmitPasswordHandler = async (e) => {
    e.preventDefault();
    console.log(inputs);
    if (inputs.newPassword !== inputs.conPassword) {
      setTimeout(() => {
        setMsg("");
      }, 5000);
      setMsg("Passwords do not match");
      toast("Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      const request = await axios({
        url: `${process.env.REACT_APP_BASEURL}/logistic/settings/change/password`,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
        data: {
          old_password: inputs.password,
          new_password: inputs.newPassword,
          confirm_password: inputs.conPassword,
        },
      });
      console.log("request.data", request.data);
      setLoading(false);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      setInputs({});
      close();
      setMsg("Details saved successfully");
      toast("Details saved successfully");
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      setMsg("Invalid details");
      toast("Invalid details");
      console.log(error.response);
    }
  };

  return (
    <>
      <Modal show={show} close={close}>
        <form onSubmit={onSubmitPasswordHandler}>
          {msg.length > 0 && (
            <div
              className={Classes.msgAlert}
              style={{
                textAlign: "center",
                marginBottom: "40px",
                display: "none",
              }}
            >
              <p style={{ margin: "0px 0px" }} className={Classes.msgItself}>
                {msg}
              </p>
            </div>
          )}

          <div className={Classes.headerSection}>
            <strong>Change Password</strong>
          </div>
          <div
            style={{ paddingBottom: "0px" }}
            className={Classes.subHeaderSection}
          >
            <Input
              required
              placeholder="Existing Password"
              label="Existing Password"
              showPassword={() => setShowExistPassword(!showExistPassword)}
              inputType={showExistPassword ? "text" : "password"}
              type="input"
              value={inputs?.password}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  password: e.target.value,
                })
              }
            />

            <div className={Classes.gridSectionHere}>
              <div>
                <Input
                  required
                  placeholder="New Password"
                  label="New Password"
                  type="input"
                  value={inputs?.newPassword}
                  showPassword={() => setShowPassword(!showPassword)}
                  inputType={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    setInputs({
                      ...inputs,
                      newPassword: e.target.value,
                    });
                    if (re.test(e.target.value)) {
                      return setShowPassMSG(false);
                    } else {
                      return setShowPassMSG(true);
                    }
                  }}
                />
                {showPassMSG && (
                  <>
                    {inputs?.newPassword?.length > 1 && (
                      <p className={Classes.must_Contain}>
                        Password must contain an uppercase, lowercase and symbol
                      </p>
                    )}
                  </>
                )}
              </div>
              <div>
                <Input
                  required
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  type="input"
                  showPassword={() => setAnoShowPassword(!anoShowPassword)}
                  inputType={anoShowPassword ? "text" : "password"}
                  value={inputs?.conPassword}
                  onChange={(e) => {
                    setInputs({
                      ...inputs,
                      conPassword: e.target.value,
                    });
                    if (e.target.value !== inputs?.newPassword) {
                      setShowComPassMSG(true);
                    } else {
                      setShowComPassMSG(false);
                    }
                  }}
                />
                {showComPassMSG && (
                  <p className={Classes.must_Contain}>Passwords do not match</p>
                )}
              </div>
            </div>
          </div>

          <div className={Classes.bottomBorder}>
            <div style={{ marginLeft: "auto", display: "flex" }}>
              <Button
                style={{ marginTop: "30px" }}
                loading={loading}
                text="Save Password"
                bgColor="#1abc9c"
              />

              <div style={{ width: "20px" }}> </div>
              {/* <Button bgColor="#6c757d" text="Cancel" /> */}
              <Button
                text="Cancel"
                onClick={close}
                style={{
                  marginLeft: "20px",
                  backgroundColor: "#fff",
                  color: "#ff0066",
                }}
              />
            </div>
          </div>
        </form>
      </Modal>

      <Modal show={showAgent} close={closeAgent}>
        <div className={Classes.headerSection}>
          <strong>Request Agent Change</strong>
        </div>
        <div
          style={{ paddingBottom: "0px" }}
          className={Classes.subHeaderSection}
        >
          <Input
            txtPlaceholder=" "
            label="Add a reason for the request:"
            type="textarea"
            inputType="password"
          />
        </div>

        <div className={Classes.bottomBorder}>
          <div style={{ marginLeft: "auto", display: "flex" }}>
            {/* <Button bgColor="#ff0166" text="Save Password" /> */}
            <div style={{ width: "20px" }}> </div>
            <Button bgColor="#6c757d" text="Yes, Request" />
          </div>
        </div>
      </Modal>

      <Modal show={showImage} close={closeImage}>
        <div className={Classes.headerSection}>
          <strong>Change Profile Image</strong>
        </div>
        <div
          style={{ paddingBottom: "0px" }}
          className={Classes.subHeaderSection}
        >
          <Input
            txtPlaceholder=" "
            label="Select an image"
            type="input"
            accept="image/*"
            inputType="file"
          />
        </div>

        <div className={Classes.bottomBorder}>
          <div style={{ marginLeft: "auto", display: "flex" }}>
            {/* <Button bgColor="#ff0166" text="Save Password" /> */}
            {/* <div style={{width: '20px'}}> </div> */}
            <Button bgColor="#6c757d" text="Change" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangePass;
