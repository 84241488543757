import React from "react";
import { useContext } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { MainContext } from "../App";
import Dashboard from "../pages/dashboard/dashboard";
import Customer from "../pages/customer/eventCustomer";
import Withdrawal from "../pages/withdrawal/eventWithdrawal";
import Orders from "../pages/orders/eventOrders";
import EachOrder from "../pages/eachOrder/eventEachOrder";
import Income from "../pages/income/eventIncome";
// import Store from '../pages/store/eventStore';
import Settings from "../pages/settings/settings";
import Nav from "../component/layout/nav/nav";
import Classes from "../component/layout/layout.module.css";
import Sidebar from "../component/layout/sidebar/sidebar";
const ProtectRoutes = () => {
  const { token, closeNav, sidebar } = useContext(MainContext);
  return token ? (
    <div className={`mt-[80px] ${Classes.allLayoutCover}`}>
      <Nav />
      <Sidebar />
      <div
        onClick={closeNav}
        className={`top-0 ${
          sidebar ? "fixed" : "hidden"
        } h-screen w-full z-[10] backdrop-blur-sm`}
        style={{
          backgroundColor: "rgba(255,255,255,.4)",
        }}
      />
      <div className="lg:pl-[290px]">
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={"/login"} />
  );
};
const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectRoutes />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="customers" element={<Customer />} />
        <Route path="withdrawal" element={<Withdrawal />} />
        <Route path="my-orders" element={<Orders />} />
        <Route path="my-orders/:id" element={<EachOrder />} />
        <Route path="income" element={<Income />} />
        <Route path="settings" exact element={<Settings />} />
      </Route>
    </Routes>
  );
};

export default ProtectedRoutes;
