import React, { useContext, useState } from "react";
import axios from "axios";
import Login from "./login";
import { MainContext } from "../../App";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const EventLogin = () => {
  let navigate = useNavigate();
  const CTX = useContext(MainContext);
  const [register, setRegister] = useState(false);
  const [msg, setMsg] = useState("");
  const [selectedStates, setSelectedStates] = useState("Abia");
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    localStorage.setItem("email", inputs.email);
    setLoading(true);
    if (register) {
      try {
        const request = await axios({
          url: `${process.env.REACT_APP_BASEURL}/logistic/register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            email: inputs.email,
          },
        });

        setLoading(false);
        console.log("register url => ", request);
        if (request.data.status === 400) throw new Error("Opps user exist");
        toast(request.data.data);
      } catch (error) {
        setLoading(false);
        if (error.response.data.message) {
          toast(error?.response?.data?.message);
        } else {
          toast(error?.response?.data?.data[0]);
        }
      }
    } else {
      try {
        // const url = await axios.post(`${process.env.REACT_APP_BASEURL}/logistic/login`, {
        const url = await axios.post(
          `${process.env.REACT_APP_BASEURL}/logistic/login`,
          {
            email: inputs.email,
            password: inputs.password,
            terms: true,
          }
        );
        setLoading(false);

        //   console.log('data => ', url.data);
        CTX.setToken(url.data.data.access_token);
        CTX.setUser(url.data.data.logistics);
      } catch (error) {
        console.log("error", error.response);
        setLoading(false);

        if (error.response) {
          if (error.response.data.detail) {
            setMsg(error.response.data.detail);
            toast(error.response.data.detail);
            return;
          } else {
            setMsg(error?.response?.data?.data.error);
            toast(error?.response?.data?.data.error);
            navigate("/verification");
            return;
          }
        } else {
          toast("Check your internet");
          return;
        }
      }
    }
  };

  // toggle registerHandler
  const setRegisterHandler = () => {
    setRegister(!register);
    setMsg("");
    setInputs({ email: "", password: "", first_name: "", last_name: "" });
  };

  return (
    <Login
      inputs={inputs}
      setInputs={setInputs}
      setSelectedStates={setSelectedStates}
      selectedStates={selectedStates}
      msg={msg}
      setMsg={setMsg}
      register={register}
      setRegister={setRegisterHandler}
      onSubmitHandler={onSubmitHandler}
      loading={loading}
      CTX={CTX}
      setLoading={setLoading}
    />
  );
};

export default EventLogin;
