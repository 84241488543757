import React from "react";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";

const AllRoutes = () => {
  return (
    <React.Fragment>
      <ProtectedRoutes />
      <PublicRoutes />
    </React.Fragment>
  );
};

export default AllRoutes;
