import React, { Suspense, useContext, useState, useEffect } from "react";
import { MainContext } from "../../App";
import LazyLoading from "../../component/lazyLoading/lazyLoading";
import Classes from "./withdrawal.module.css";
import { EachPageHeader } from "../../component/layout/eachPageHeader/eachPageHeader";
import DashboardCard from "../../component/dashboardCard/dashboardCard";

import Button from "../../component/button/button";
import {
  AiOutlineLock,
  AiOutlineSearch,
  AiOutlineWallet,
} from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as Money } from "../../asset/money.svg";
import { Months } from "../../data/monthsJson";

import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import { ReactComponent as Left } from "../../asset/left.svg";
import ModalSection from "./modalSection";
import { GetUserStatistics } from "../../helper/getUserStatistics";

const Products = ({
  showModal,
  setShowModal,
  loading,
  currentItems,
  pageCount,
  refReactPaginate,
  handlePageClick,
  isEdit,
  withdrawal,
  inputs,
  category,
  setInputs,
  setCategory,
  onChangeHandler,
  selectOnChangeHandler,
}) => {
  const CTX = useContext(MainContext);
  const [inputValue, setInputValue] = useState("");
  const { data, getSta } = GetUserStatistics();

  const mappedWithdrawal = currentItems
    .filter((v) => v.created.toLowerCase().includes(inputValue.toLowerCase()))
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Amount: </strong>
          {v.amount}
        </div>

        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Date: </strong>
          {v.created.split("T")[0]}
        </div>
        <div className={Classes.myOrderItemCover}>
          <strong className={Classes.strongSection}>Status: </strong>
          {v.status}
        </div>
      </div>
    ));

  const mappedLoading = Array(7)
    .fill("a")
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  useEffect(() => {
    getSta();
    if (data.balance) {
      CTX.setStats(data);
    }
  }, []);
  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Withdrawal - Passward Logistics </title>
        <meta name="og:title" content="Withdrawal - Passward Logistics" />
        <meta name="og:description" content="Withdrawal - Passward Logistics" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: "0px 20px",
          boxSizing: "border-box",
          width: "100%",
          filter: "blur(var(--body-blur,0px))",
        }}
      >
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Withdrawal" />

          <div className={Classes.gridDashboardCard}>
            <DashboardCard
              label="Wallet Balance"
              num={
                withdrawal.wallet_balance
                  ? "₦" + " " + withdrawal.wallet_balance
                  : "₦" + " " + 0
              }
              svg={<AiOutlineWallet />}
            />

            <DashboardCard
              label="Safe Lock"
              num={
                withdrawal.safe_lock
                  ? "₦" + " " + withdrawal.safe_lock
                  : "₦" + " " + 0
              }
              svg={<AiOutlineLock />}
            />

            <div className={Classes.DashboardCardCover}>
              <div className={Classes.svgCover}>
                <Money />
              </div>
              <div className={Classes.textCoverSection}>
                {/* <p style={{margin: '0px 0px  5px 0px', fontSize: '13px'}}>
                  Total Revenue
                </p> */}

                <select
                  onChange={(e) => selectOnChangeHandler(e.target.value)}
                  className={Classes.selectRevenue}
                >
                  <option>All Revenue</option>
                  {Months.map((v, index) => (
                    <option key={index}>{v.name}</option>
                  ))}
                </select>

                <strong style={{ fontSize: "13px" }}>
                  {withdrawal?.monthly_balance?.amount}
                </strong>
              </div>
            </div>
          </div>

          <div className={Classes.subNavCover}>
            <form className={Classes.firstForm}>
              <input
                placeholder="Search "
                className={Classes.searchFormSection}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button className={Classes.buttonFormSection}>
                <AiOutlineSearch fill="#fff" size="1.3em" />
              </button>
            </form>

            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>Withdrawal</h3>
              <div className={Classes.sideEachPageHeader}>
                <form className={Classes.secondForm}>
                  <input
                    placeholder="Search "
                    className={Classes.searchFormSection}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
                <Button
                  onClick={() => setShowModal(true)}
                  text="Withdraw Fund"
                  style={{ height: "max-content", marginTop: "0px" }}
                />

                {/* <Button text="Withdraw Funds" style={{marginTop: "0px", marginBottom: "10px"}} onClick={() => setShowModal(!showModal)} /> */}
              </div>
            </div>
          </div>

          {/* here is the listing of all the products with the headers */}
          {loading && (
            <div className={Classes.cardCover}>
              <div className={Classes.mappedStoresCover}>
                <div className={Classes.cardListingCover}>
                  <div className={Classes.cardHeader}>
                    <strong>Amount</strong>
                    <strong>Date</strong>
                    <strong>Status</strong>
                  </div>

                  {mappedLoading}
                </div>
              </div>
            </div>
          )}

          {loading ? (
            <> </>
          ) : (
            <>
              {/* here is the listing of all the products with the headers */}
              {!mappedWithdrawal.length < 1 && (
                <div className={Classes.cardCover}>
                  <div className={Classes.mappedStoresCover}>
                    <div className={Classes.cardListingCover}>
                      <div className={Classes.cardHeader}>
                        <strong>Amount</strong>
                        <strong>Date</strong>
                        <strong>Status</strong>
                      </div>

                      {mappedWithdrawal}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {!loading && (
            <>
              {mappedWithdrawal.length < 1 && (
                <div className={Classes.emptyArrayCover}>
                  <p>You do not have any wallet history</p>
                  <Button
                    text="Withdraw Funds"
                    style={{ marginTop: "0px", marginBottom: "10px" }}
                    onClick={() => setShowModal(!showModal)}
                  />
                </div>
              )}
            </>
          )}

          {mappedWithdrawal.length > 0 && (
            <div
              style={{ display: "flex", marginBottom: "30px", width: "100%" }}
            >
              <div className={Classes.showingCover}>
                <select
                  onChange={onChangeHandler}
                  className={Classes.showingSelectCover}
                >
                  <option>Showing 1</option>
                  <option defaultValue selected>
                    Showing 10
                  </option>
                  <option>Showing 25</option>
                  <option>Showing 50</option>
                  <option>Showing 100</option>
                </select>
              </div>

              <ReactPaginate
                ref={refReactPaginate}
                breakLabel="..."
                nextLabel={<Left style={{ transform: "rotate(180deg)" }} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<Left />}
                className={Classes.paginationClass}
                renderOnZeroPageCount={null}
                disabledClassName={Classes.disabledClassName}
              />
            </div>
          )}
        </Suspense>
      </div>
      <ModalSection
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={isEdit}
        inputs={inputs}
        category={category}
        setInputs={setInputs}
        setCategory={setCategory}
      />
    </div>
  );
};

export default Products;
