import React, { useState } from "react";

import Classes from "./login.module.css";
import SemiLogo from "../../asset/logo_semi.png";
import RightLogo from "../../asset/star.png";
import Logo from "../../asset/logo.png";
import Input from "../../component/input/input";
import Button from "../../component/button/button";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Login = ({
  inputs,
  setInputs,
  setRegister,
  register,
  onSubmitHandler,
  msg,
  loading,
  CTX,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassMSG, setShowPassMSG] = useState(false);
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

  return (
    <div className={Classes.LoginFormCover}>
      <Helmet>
        <title>Login - Passward Logistics </title>
        <meta name="og:title" content="Login - Passward Logistics" />
        <meta name="og:description" content="Login - Passward Logistics" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={Classes.relative}>
        <nav className={Classes.nav}>
          <Link to="/" onClick={() => CTX.closeLogin()}>
            <img src={Logo} className={Classes.logo} alt="Passward Logo" />
          </Link>
        </nav>
        <h1 className={Classes.header}>{register ? "Register" : "Login"}</h1>
        <form
          autoComplete="off"
          className={Classes.formCover}
          onSubmit={onSubmitHandler}
        >
          {msg?.length > 0 && (
            <div
              className={Classes.msgAlert}
              style={{
                textAlign: "center",
                marginBottom: "40px",
                display: "none",
              }}
            >
              <p style={{ margin: "0px 0px" }} className={Classes.msgItself}>
                {msg}
              </p>
            </div>
          )}

          <div className={Classes.main_width}>
            <Input
              type="input"
              placeholder="example@gmail.com"
              inputType="email"
              value={inputs?.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
              required={true}
              z
              labelStyle={{ color: "#000033" }}
              label="Email"
              style={{
                backgroundColor: "#fff",
                height: "50px",
                border: "1px solid #ff0066",
              }}
            />
          </div>

          {!register && (
            <div className={Classes.main_width} style={{ marginTop: "8px" }}>
              <Input
                type="input"
                required={true}
                value={inputs?.password}
                onChange={(e) => {
                  setInputs({ ...inputs, password: e.target.value });
                  if (re.test(e.target.value)) {
                    return setShowPassMSG(false);
                  } else {
                    return setShowPassMSG(true);
                  }
                }}
                labelStyle={{ color: "#000033" }}
                placeholder="* * * * * * *"
                showPassword={() => setShowPassword(!showPassword)}
                inputType={showPassword ? "text" : "password"}
                label="Password"
                style={{
                  backgroundColor: "#fff",
                  height: "50px",
                  border: "1px solid #ff0066",
                }}
              />
              {register && (
                <>
                  {showPassMSG && (
                    <>
                      {inputs?.password?.length > 1 && (
                        <p className={Classes.must_Contain}>
                          Password must contain an Uppercase, Lowercase and
                          Symbol
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {!register && (
            <div
              className={Classes.main_width}
              style={{ marginTop: "8px", flexDirection: "row" }}
            >
              <div>
                <input type="checkbox" className={Classes.inputCheckbox} />
                <label className={Classes.coloredLabel}>
                  Keep me Signed In
                </label>
              </div>
              <Link
                style={{
                  marginLeft: "auto",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to="/forgot-password"
              >
                <label className={Classes.coloredLabel}>Forgot Password?</label>
              </Link>
            </div>
          )}
          {/* {!register && ( */}
          <div className={Classes.main_width} style={{ marginTop: "38px" }}>
            <Button
              text={register ? "Register" : "Login"}
              style={{ width: "100%", height: "40px", marginBottom: "50px" }}
              loading={loading}
            />
          </div>
          {/* )} */}
          <div className={Classes.centeredText}>
            {!register && (
              <span style={{ cursor: "pointer" }} onClick={setRegister}>
                Don’t have account?
                <span
                  style={{
                    marginLeft: "5px",
                    color: "#FF0066",
                    textDecoration: "underline",
                  }}
                >
                  Register
                </span>
              </span>
            )}

            {register && (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setRegister(!register)}
              >
                Already a User?
                <span
                  style={{
                    marginLeft: "5px",
                    color: "#FF0066",
                    textDecoration: "underline",
                  }}
                >
                  Login
                </span>
              </span>
            )}
          </div>
        </form>
        <img alt=" " src={SemiLogo} className={Classes.topRightLogo} />
        <img alt=" " src={RightLogo} className={Classes.rightLogo} />
        <img alt=" " src={RightLogo} className={Classes.leftLogo} />
        <img alt=" " src={RightLogo} className={Classes.topLeftLogo} />
        <img alt=" " src={SemiLogo} className={Classes.semiLogo} />
      </div>
    </div>
  );
};

export default Login;
