import { useState, useContext } from "react";
import { MainContext } from "../App";
import axios from "axios";
// import Loading from '../components/loading/loading';

export const GetUserDetails = () => {
  const CTX = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const getUser = async () => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASEURL}/logistic/settings`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.getItem("a_tk")}`,
          },
        }
      );

      setLoading(false);
      // if (request.data.detail === 'access_token expired') {
      //   logout();
      //   return;
      // } else {
      CTX.setUser(request.data?.data);
      setData(request.data?.data);
      return;
      // }
    } catch (error) {
      setLoading(false);
      // setMsg('Invalid login details');
      console.log(error);
    }
  };

  return { loading, getUser, data: data };
};
