import React, { useEffect, useState, useContext } from "react";
import Classes from "./withdrawalSettings.module.css";
import Input from "../../component/input/input";
import Card from "../../component/card/card";
import Button from "../../component/button/button";
import { Banks } from "../../data/bankJson";
import axios from "axios";
import { MainContext } from "../../App";
import { GetUserDetails } from "../../helper/getUserHelper";
import { useLocation } from "react-router-dom";
import { States } from "../../data/stateJson";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import useValidateBankMutation from "../../api/banks/validate-bank-account";

const RightSide = () => {
  const [toggle, setToggler] = useState(false);
  const location = useLocation();
  const { getUser } = GetUserDetails();
  const CTX = useContext(MainContext);
  const [withdrawalInput, setWithdrawalInput] = useState({
    ...CTX.user,
  });
  const [state, setState] = useState("");
  const [inputs, setInputs] = useState({ ...CTX.user });
  const [selectedBank, setSelectedBank] = useState(
    inputs?.bank || "Abbey Mortgage Bank"
  );
  // const [loadingFetch, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    mutate,
    isLoading: loadingAcctDetails,
    data: bankDetails,
    isIdle,
  } = useValidateBankMutation();
  const bank_code = Banks.filter((v) => v.name === selectedBank)[0].code;
  const onSubmitBankHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      await axios({
        url: `${process.env.REACT_APP_BASEURL}/logistic/settings/withdraw/update`,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
        data: {
          bank: selectedBank,
          bank_code,
          account_no: withdrawalInput.account_no,
          account_name: withdrawalInput.account_name,
        },
      });
      setLoading(false);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      setMsg("Details saved successfully");
      toast("Details saved successfully");
      getUser();
    } catch (error) {
      setLoading(false);
      console.log("error => ", error.response);
      if (error.response) {
        toast(error.response.data.data);
        return;
      } else {
        toast("Check your internet");
        return;
      }
    }
  };

  const onSubmitDetailsHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      var formData = await new FormData();
      // formData.append('photo', photo.target.files[Object.keys(photo.target.files)]);
      // formData.append('vehicle', vehicle.target.files[Object.keys(vehicle.target.files)]);
      formData.append("company_name", inputs.company_name);
      formData.append("phone", inputs.phone);
      formData.append("price", inputs.price);
      formData.append("extra", inputs.extra);
      formData.append("cac", inputs.cac);
      formData.append("address", inputs.address);
      formData.append("state", state);
      formData.append("city", inputs.city);
      formData.append("no_of_vehicles", inputs.no_of_vehicles);
      formData.append("no_of_vehicles", inputs.no_of_vehicles);

      // console.log("photo => ", photo);
      // console.log("vehicle => ", vehicle);

      const request = await axios({
        url: `${process.env.REACT_APP_BASEURL}/logistic/settings/update`,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
        data: formData,
      });
      // console.log('request', request);
      setLoading(false);
      setMsg("Details saved successfully");
      toast("Details saved successfully");
      console.log("request.data.data => ", request.data);
      getUser();
    } catch (error) {
      setLoading(false);
      console.log(error);
      console.log(error.response);
      if (error.response) {
        toast(error.response.data.data);
        return;
      } else {
        toast("Check your internet");
        return;
      }
    }
  };

  useEffect(() => {
    if (location.search.split("=")[1] === "withdrawal") {
      setToggler(true);
    } else {
      setToggler(false);
    }
    // window.location.search.split('=')[0] === '?token'
  }, [location]);

  // uploading the profile image
  const onBlurImageHandlerPhoto = async (e) => {
    try {
      var formData = await new FormData();
      formData.append("photo", e.target.files[Object.keys(e.target.files)]);
      // formData.append('photo', photo.target.files[Object.keys(photo.target.files)]);
      await axios({
        url: `${process.env.REACT_APP_BASEURL}/logistic/settings/update`,
        data: formData,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
      });
      getUser();
    } catch (error) {
      console.log(error);
    }
  };

  const onBlurImageHandlerVehicle = async (e) => {
    try {
      var formData = await new FormData();
      formData.append("vehicle", e.target.files[Object.keys(e.target.files)]);
      // formData.append('vehicle', vehicle.target.files[Object.keys(vehicle.target.files)]);
      await axios({
        url: `${process.env.REACT_APP_BASEURL}/logistic/settings/update`,
        data: formData,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
      });
      getUser();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!CTX.user) return;
    setState(CTX?.user?.state);
  }, []);

  useEffect(() => {
    if (isIdle) return;
    setWithdrawalInput((prev) => ({
      ...prev,
      account_name: bankDetails?.data?.account_name ?? "",
    }));
  }, [bankDetails]);
  return (
    <div>
      <div className={Classes.pageHeader}>Account/Withdrawal Settings</div>
      <Card style={{ height: "max-content" }}>
        <div className={Classes.paddingCard}>
          <div className={Classes.flexTwo}>
            <Link
              to="/settings?type=account"
              style={{ textDecoration: "none", marginRight: "50px" }}
            >
              <div
                style={{
                  backgroundColor: !toggle ? "#ff0166" : "#f7f7f7",
                  color: !toggle ? "#fff" : "#6c757d",
                }}
                onClick={() => {
                  setToggler(false);
                  setMsg("");
                }}
                className={Classes.twoRoutes}
              >
                Account
              </div>
            </Link>
            <Link
              to="/settings?type=withdrawal"
              style={{ textDecoration: "none" }}
            >
              <div
                style={{
                  backgroundColor: toggle ? "#ff0166" : "#f7f7f7",
                  color: toggle ? "#fff" : "#6c757d",
                }}
                onClick={() => {
                  setToggler(true);
                  setMsg("");
                }}
                className={Classes.twoRoutes}
              >
                Withdrawal
              </div>
            </Link>
          </div>

          {!toggle && (
            <form onSubmit={onSubmitDetailsHandler} autoComplete="off">
              <input
                type="hidden"
                autoComplete="none"
                name="hidden"
                // type="text"
                style={{ display: "none" }}
              />
              {msg.length > 0 && (
                <div
                  className={Classes.msgAlert}
                  style={{
                    textAlign: "center",
                    marginBottom: "40px",
                    display: "none",
                  }}
                >
                  <p
                    style={{ margin: "0px 0px" }}
                    className={Classes.msgItself}
                  >
                    {msg}
                  </p>
                </div>
              )}
              <div className={Classes.personalInfoCover}>
                <span style={{ lineHeight: "1" }}>PERSONAL INFORMATION</span>
              </div>
              {/* <div className={Classes.gridTwo}> */}
              <Input
                value={inputs?.company_name}
                onChange={(e) =>
                  setInputs({ ...inputs, company_name: e.target.value })
                }
                type="input"
                label="Logistics Company Name"
              />
              {/* <Input
                  value={inputs?.last_name}
                  onChange={(e) =>
                    setInputs({...inputs, last_name: e.target.value})
                  }
                  type="input"
                  label="Last Name"
                /> */}
              {/* </div> */}

              <div className={Classes.gridTwo}>
                <Input
                  type="input"
                  inputType="email"
                  label="Email Address"
                  value={CTX.user.email}
                  readOnly={true}
                  msg="Registered email address can not be modified."
                />
                <Input
                  value={inputs?.phone}
                  onChange={(e) =>
                    setInputs({ ...inputs, phone: e.target.value })
                  }
                  type="input"
                  label="Contact Number/Help Line"
                />
              </div>

              <Input
                type="input"
                inputType="file"
                accept="image/png, image/gif, image/jpeg"
                label="Profile Image"
                style={{ backgroundColor: "#003" }}
                onBlur={onBlurImageHandlerPhoto}
                // options={States.map(v => v.name)}
              />

              <div className={Classes.gridTwo}>
                <Input
                  value={inputs?.price}
                  onChange={(e) =>
                    setInputs({ ...inputs, price: e.target.value })
                  }
                  type="input"
                  label="Delivery Fee"
                />

                <Input
                  value={inputs?.extra}
                  onChange={(e) =>
                    setInputs({ ...inputs, extra: e.target.value })
                  }
                  type="input"
                  label="Extra Delivery Fee"
                />

                <Input
                  value={inputs?.cac}
                  onChange={(e) =>
                    setInputs({ ...inputs, cac: e.target.value })
                  }
                  type="input"
                  label="CAC Registered Business Name"
                />

                <Input
                  value={inputs?.address}
                  onChange={(e) =>
                    setInputs({ ...inputs, address: e.target.value })
                  }
                  type="input"
                  label="Business Address"
                />

                <Input
                  selected={inputs?.state}
                  type="select"
                  onChange={(e) => setState(e.target.value)}
                  label="State of Residence"
                  options={["Choose State"].concat(States.map((v) => v.name))}
                />
                <Input
                  value={inputs?.city}
                  onChange={(e) =>
                    setInputs({ ...inputs, city: e.target.value })
                  }
                  type="input"
                  label="Location/Specify City"
                />
                <Input
                  value={inputs?.no_of_vehicles}
                  onChange={(e) =>
                    setInputs({ ...inputs, no_of_vehicles: e.target.value })
                  }
                  type="input"
                  label="Number of Vehicle Owned"
                />
                <Input
                  type="input"
                  inputType="file"
                  accept="image/png, image/gif, image/jpeg"
                  label="Company Branded Vehicle"
                  style={{ backgroundColor: "#003" }}
                  onBlur={onBlurImageHandlerVehicle}
                  // options={States.map(v => v.name)}
                />

                <div></div>
              </div>

              <Button
                style={{ marginTop: "30px" }}
                loading={loading}
                // style={{ height: "38px" }}
                text="Save Changes"
                bgColor="#1abc9c"
              />
            </form>
          )}

          {toggle && (
            <form onSubmit={onSubmitBankHandler}>
              {msg.length > 0 && (
                <div
                  className={Classes.msgAlert}
                  style={{
                    textAlign: "center",
                    marginBottom: "40px",
                    display: "none",
                  }}
                >
                  <p
                    style={{ margin: "0px 0px" }}
                    className={Classes.msgItself}
                  >
                    {msg}
                  </p>
                </div>
              )}
              <div className={Classes.personalInfoCover}>
                <span style={{ lineHeight: "1" }}>PERSONAL INFORMATION</span>
              </div>
              <Input
                type="select"
                selected={inputs?.bank}
                options={Banks.map((v) => {
                  return v.name;
                })}
                label="Bank"
                onChange={(e) => setSelectedBank(e.target.value)}
              />
              <div className={Classes.gridTwo}>
                <Input
                  required
                  value={withdrawalInput?.account_name}
                  onChange={(e) =>
                    setWithdrawalInput({
                      ...withdrawalInput,
                      account_name: e.target.value,
                    })
                  }
                  type="input"
                  inputType="text"
                  label="Account Name"
                  disabled
                />
                <Input
                  required
                  value={withdrawalInput?.account_no}
                  onChange={(e) => {
                    setWithdrawalInput({
                      ...withdrawalInput,
                      account_no: e.target.value,
                      // account_name:
                    });
                    mutate({
                      account_number: e.target.value,
                      bank_code,
                    });
                  }}
                  type="input"
                  label="Account No"
                />
              </div>

              <Button
                style={{ height: "38px" }}
                text="Save Changes"
                loading={loading || loadingAcctDetails}
              />
            </form>
          )}
        </div>
      </Card>
    </div>
  );
};

export default RightSide;
