import React, { useContext, useEffect } from "react";
import Classes from "./dashboard.module.css";
import {
  EachPageHeader,
  PageHeader,
} from "../../component/layout/eachPageHeader/eachPageHeader";
import { Helmet } from "react-helmet";
import { MainContext } from "../../App";
import DashboardCard from "../../component/dashboardCard/dashboardCard";
// import {ReactComponent as Money} from '../../asset/money.svg';
import { ReactComponent as Store } from "../../asset/store.svg";
// import {ReactComponent as Box} from '../../asset/box.svg';
import { ReactComponent as Bag } from "../../asset/bag.svg";
import RecentOrder from "./recentOrder";
import { AiOutlineWallet } from "react-icons/ai";
import { GetUserStatistics } from "../../helper/getUserStatistics";

const Home = () => {
  const CTX = useContext(MainContext);
  const { data, getSta } = GetUserStatistics();
  useEffect(() => {
    getSta();
  }, []);

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Overview - Passward Logistics </title>
        <meta name="og:title" content="Overview - Passward Logistics" />
        <meta name="og:description" content="Settings - Passward Logistics" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: "0px 20px",
          boxSizing: "border-box",
          width: "100%",
          filter: "blur(var(--body-blur,0px))",
        }}
      >
        <EachPageHeader first="Home" second="Overview" />
        <PageHeader name="Overview" />
        <div className={"flex gap-3 justify-between sm:flex-row flex-col "}>
          <DashboardCard
            label="Total Revenue"
            // num={'₦' + ' ' + data.revenue}
            num={
              "₦" + " " + `${data.revenue === undefined ? "0" : data.revenue}`
            }
            svg={<Store width="16px" />}
          />
          <DashboardCard
            label="Wallet Balance"
            num={
              "₦" + " " + `${data.balance === undefined ? "0" : data.balance}`
            }
            svg={<AiOutlineWallet />}
          />
          <DashboardCard
            label="Orders"
            num={data.orders === undefined ? "0" : data.orders}
            svg={<Bag />}
          />
        </div>
        <p className="text-right mt-5">
          Logistics name: {CTX.user.company_name}
        </p>
        <RecentOrder />
      </div>
    </div>
  );
};

export default Home;
